// @flow

import React, { useEffect, useMemo } from "react";
import { Box, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { ContextMenu } from "@echo/ui";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { CustomCheckbox } from "../checkbox/checkbox";
import { useRef } from "react";
import {
  DATAGRID_HEADER_ROW_CELL_TESTID,
  DATAGRID_HEADER_ROW_TESTID,
  DATAGRID_HEADER_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { useScrollContext } from "../../hooks/use-scroll-context";
import { FilterRow } from "./filter-row";
import { useDragContext } from "../../hooks/useDragContext";
import { HeaderCell } from "../cell/header-cell/header-cell";

export const Header = ({ columns }): React.Node => {
  const {
    data,
    disableFilters,
    actions: { onSelectAll },
    selectedItems,
    selectionContextActions,
    contextActions,
    selectionMode,
  } = useDatagridContext();

  const { headerRef, bodyRef } = useScrollContext();

  const {
    isContextMenuOpened,
    openContextMenu,
    closeContextMenu,
    clickPosition,
  } = useContextMenu();

  const {
    isContextMenuOpened: isHeaderContextOpened,
    openContextMenu: openHeaderContextMenu,
    closeContextMenu: closeHeaderContextMenu,
    clickPosition: headerClickPosition,
  } = useContextMenu();

  const { containerRef } = useDragContext();

  const handleChecked = (e) => {
    const isChecked = e.target.checked;
    const idArr = data.map((el) => getId(el)?.toString());

    if (isChecked) onSelectAll(idArr, "select");
    if (!isChecked) onSelectAll(idArr, "unselect");
  };

  const dataIdArr = useMemo(
    () => data.map((i) => getId(i)?.toString()),
    [data],
  );

  const isAllChecked = useMemo(
    () =>
      selectedItems.length > 0 &&
      dataIdArr.filter((d_id) => !selectedItems.find((s_id) => d_id == s_id))
        .length === 0,
    [dataIdArr, selectedItems],
  );

  const headerContextActions = useMemo(
    () => contextActions.filter((a) => a?.position?.includes("header")),
    [contextActions],
  );

  return (
    <Thead
      minHeight="fit-content"
      width="100%"
      display="block"
      borderBottom="1px solid black"
      data-testid={DATAGRID_HEADER_TESTID}
      overflow="hidden"
      ref={headerRef}
    >
      {isContextMenuOpened && selectionContextActions && (
        <ContextMenu
          isOpen={isContextMenuOpened}
          onClose={closeContextMenu}
          clickPosition={clickPosition}
          actions={selectionContextActions}
        />
      )}
      {isHeaderContextOpened && headerContextActions && (
        <ContextMenu
          isOpen={isHeaderContextOpened}
          onClose={closeHeaderContextMenu}
          clickPosition={headerClickPosition}
          actions={headerContextActions}
        />
      )}
      <Tr
        data-testid={DATAGRID_HEADER_ROW_TESTID}
        onContextMenu={openHeaderContextMenu}
      >
        {columns.map((col) =>
          col.name === "checkbox" ? (
            selectionMode !== "none" && (
              <HeaderCell
                column={col}
                width={col.width}
                index={col.order}
                key={col.name}
                data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
                onContextMenu={openContextMenu}
              >
                <CustomCheckbox
                  isChecked={isAllChecked}
                  onChange={handleChecked}
                />
              </HeaderCell>
            )
          ) : (
            <HeaderCell
              column={col}
              width={col.width}
              index={col.order}
              key={col.name}
              data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
            >
              {col.displayName || col.name}
            </HeaderCell>
          ),
        )}
      </Tr>
      {!disableFilters && <FilterRow columns={columns} />}
    </Thead>
  );
};
