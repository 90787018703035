import { getPropertyCaseInsensitive } from "../../../../utils/property/case-insensitive-property-utils";

const getFieldsByProperty = (component, property) => {
  const { childrenComponents, componentProps } = component;

  if (childrenComponents && childrenComponents.length > 0) {
    return childrenComponents
      .map((cmp) => getFieldsByProperty(cmp, property))
      .flat(Infinity)
      .filter((el) => el);
  }

  if (getPropertyCaseInsensitive(componentProps, property.toLowerCase())) {
    return component;
  }

  return null;
};

export const getUniqueFields = (component) =>
  getFieldsByProperty(component, "isUnique");

export const getRequiredFields = (component) =>
  getFieldsByProperty(component, "isRequired");
