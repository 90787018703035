import { toast } from "react-toastify";
import { executeBlockByGroup } from "../../../process-executor";
import { getModelId } from "./get-id";
import { getModelFromResult } from "./get-model-from-result";
import { updateModel } from "./update-model";

const getProcessParams = (ctx, staticParams, params, model, action) => {
  return {
    group: {
      group: action.name,
      tag: getModelId(model) ? "UPDATE" : "CREATE",
    },
    staticParams: { ...staticParams, ...action.staticParams, __model: model },
    params: [
      ...(params?.filter((c) => c !== null) ?? []),
      { action: getModelId(model) ? "update" : "create" },
      { componentId: ctx?.component?.id },
      { isOpen: false },
    ],
  };
};

export const executeProcessGroup = async (
  ctx,
  staticParams,
  params,
  action,
  model,
  systemParams,
) => {
  const disableToast = staticParams.disableToast;
  const blockGroupParams = getProcessParams(
    ctx,
    staticParams,
    params,
    model,
    action,
  );

  const res = await executeBlockByGroup(
    ctx,
    blockGroupParams.group,
    blockGroupParams.staticParams,
    blockGroupParams.params,
    systemParams,
  );

  if (res) {
    if (!disableToast) {
      toast.success("Saved successfully.");
    }
    const resultModel = getModelFromResult(res);
    updateModel(resultModel, ctx);
    return { ...model, ...resultModel };
  }

  return model;
};
