import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Button, HStack } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";

const QuestionAlert = ({
  open,
  question,
  onAction,
  onClose,
  designerMode,
  rootProps,
  header = "Please answer the question",
  alertMode = "Yes/No",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = (action) => {
    if (onAction) {
      Promise.all([handleClose(), onAction(action)]);
    } else {
      handleClose();
    }
  };

  const { nodeRef, ...restRootProps } = rootProps;

  return (
    <>
      <Button
        ref={nodeRef}
        {...restRootProps}
        onClick={designerMode ? rootProps.onClick : () => setIsOpen(!isOpen)}
      >
        Odblokuj
      </Button>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>

            <AlertDialogBody>{question}</AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                {(alertMode ?? "").split("/").map((elem, index) =>
                  index === 0 ? (
                    <Button
                      key={`alert-button-${elem}`}
                      colorScheme="red"
                      onClick={() => handleClick(elem)}
                    >
                      {elem}
                    </Button>
                  ) : (
                    <Button
                      key={`alert-button-${elem}`}
                      variant="ghost"
                      onClick={() => handleClick(elem)}
                    >
                      {elem}
                    </Button>
                  ),
                )}
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

QuestionAlert.propTypes = {
  open: PropTypes.bool,
  question: PropTypes.string,
  header: PropTypes.string,
  alertMode: PropTypes.oneOf(["Yes/No", "Yes/No/Cancel", "Ok/Cancel", "Ok"]),
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  designerMode: PropTypes.bool,
  rootProps: PropTypes.any,
};

export default withBaseComponent(QuestionAlert);
