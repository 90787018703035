import { useCallback } from "react";
import { useDataReducer } from "../reducers/data-reducer";
import { fetchData } from "../utils/fetch-data";
import { useTimeoutEffect } from "./use-timeout-effect";
import { uuidv4 } from "@echo/tools/src";
import { createStore } from "../utils/requests-store";
import { showErrorToast } from "../../../../../echo-error-toast";

export const useData = (context, dataSource, settings, name) => {
  const { state, setState, setField } = useDataReducer();
  const { filters, page } = state;
  const { sorting, itemsPerPage } = settings;

  const getData = useCallback(
    async (params) =>
      await fetchData(
        context,
        dataSource,
        {
          sorting: (params && params.sorting) || sorting,
          page:
            params && typeof params.page === "number"
              ? params.page
              : state.page,
          itemsCount: state.itemsCount,
          filters: (params && params.filters) || state.filters,
          itemsPerPage: (params && params.itemsPerPage) || itemsPerPage,
        },
        name,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource, filters, name, page, sorting, itemsPerPage],
  );

  const refreshData = useCallback(() => {
    setField("loading", true);
    getData()
      .then(setState)
      .catch(showErrorToast)
      .finally(() => setField("loading", false));
  }, [getData, setState, setField]);

  const onChange = (type, value) => setField(type, value);

  const { addRequest, removeRequest, getRequests } = createStore(name);

  const request = () => {
    const uuid = uuidv4();
    addRequest(uuid);
    setField("loading", true);
    getData()
      .then((res) => {
        const requests = getRequests();
        if (requests[requests.length - 1] === uuid) {
          setState(res);
          setField("loading", false);
        }
      })
      .catch(showErrorToast)
      .finally(() => {
        removeRequest(uuid);
      });
  };

  useTimeoutEffect(
    request,
    [getData, context?.state?.__mounted],
    dataSource &&
      dataSource.id &&
      !settings.loading &&
      context?.state?.__mounted,
    500,
  );

  return { state, onChange, refreshData, getData };
};
