import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import EchoRouterSwitchContext from "./echo-router-switch-context";
import EchoRouterContext from "./echo-router-context";

const EchoRouterSwitch = ({ children }) => {
  const { getOpenedPages } = useContext(EchoRouterContext);
  const openedPages = getOpenedPages();

  const switchState = useMemo(
    () => ({
      openedPagesUris: openedPages.map(
        (p) =>
          `/${encodeURI(p.key)}${p.uri.startsWith("/") ? "" : "/"}${p.uri}`,
      ),
    }),
    [openedPages],
  );

  return (
    <EchoRouterSwitchContext.Provider value={switchState}>
      {children}
    </EchoRouterSwitchContext.Provider>
  );
};

EchoRouterSwitch.propTypes = { children: PropTypes.node.isRequired };

export default EchoRouterSwitch;
