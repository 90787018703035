import { useMapsLibrary } from "@vis.gl/react-google-maps";

export const useGeocoding = () => {
  const lib = useMapsLibrary("geocoding");

  if (!lib) return null;

  const geocode = new lib.Geocoder().geocode;
  const status = lib.GeocoderStatus;

  const getLocationByAddress = async (addr) => {
    try {
      const { results } = await geocode({ address: addr });
      const address = results[0].formatted_address;
      const position = results[0].geometry.location;
      const type = results[0].types[0];
      return { address, position, type };
    } catch {
      return null;
    }
  };

  const getLocationByLatLng = async ({ lat, lng }) => {
    try {
      const { results } = await geocode({ location: { lat, lng } });
      const address = results[0].formatted_address;
      const position = results[0].geometry.location;
      const type = results[0].types[0];
      return { address, position, type };
    } catch {
      return null;
    }
  };

  return { geocode, status, getLocationByAddress, getLocationByLatLng };
};
