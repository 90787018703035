import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const ContextActionSelect = () => {
  const { contextActions } = useDatagridContext();
  return (
    <Menu>
      <MenuButton
        width="fit-content"
        as={Button}
        variant="outline"
        rightIcon={<ChevronDownIcon />}
      >
        Actions
      </MenuButton>
      <MenuList>
        {contextActions.map((a) => (
          <MenuItem onClick={a.action} color={a.color} key={a.label}>
            {a.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
