import PropTypes from "prop-types";
import PermissionEditor from "./permission-editor";
import { Box, useDisclosure } from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { useCallback } from "react";

const PermissionEditorEditCell = ({
  propTypes,
  propValue,
  onChange,
  designerPortalRef,
  componentId,
  model,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnDelete = useCallback(() => {}, []);

  return (
    <>
      <PermissionEditor
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        modalProps={{ portalProps: { containerRef: designerPortalRef } }}
        permissions={propValue}
        properties={propTypes?.map((prop) => ({
          name: prop.propName,
        }))}
        onChange={onChange}
        componentId={componentId}
        location={model?.name}
      />
      <Box
        display="flex"
        justifyContent={propValue ? "space-between" : "flex-end"}
        flexDirection="row"
        marginLeft="8px"
      >
        <Box
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {Array.isArray(propValue) && `Length: ${propValue.length}`}
        </Box>
        <button onClick={onOpen} tabIndex={-1}>
          ...
        </button>
        <button onClick={handleOnDelete} tabIndex={-1}>
          <FiTrash2 size="16px" />
        </button>
      </Box>
    </>
  );
};

PermissionEditorEditCell.propTypes = {
  propTypes: PropTypes.any,
  propValue: PropTypes.array,
  onChange: PropTypes.func,
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  componentId: PropTypes.number,
  model: PropTypes.any,
};

export default PermissionEditorEditCell;
