import PropTypes from "prop-types";
import { Box, Input } from "@chakra-ui/react";
import DatagridFilter from "../filter/filter";
import { useDebouncedValueState } from "../../../../../../hooks/shared/use-debounced-value-state";
import { FilterCell } from "./filter-cell/filter-cell";
import { getColumns, selectColumnWidth } from "../utils/columns/column-utility";
import { getFilterObj, getFilterState } from "./utils/filter-utils";

const DataGridFilterHeader = ({
  globalFilter,
  onGlobalFilterChange,
  filterOptions,
  enableGlobalFilter: enableGlobalFilterOptions,
  filterType = "column",
  columnDefinitions,
  selectionMode,
  tableDimensions,
  fontSize,
}) => {
  const visibleColumns = columnDefinitions.filter(
    (col) => col.isVisible !== false,
  );

  const columnsWithFixedSizes = getColumns(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
    tableDimensions,
  );

  const { columnFilters, onColumnFiltersChange } = filterOptions;

  const [columnFiltersState, setColumnFiltersState] = useDebouncedValueState(
    columnFilters,
    onColumnFiltersChange,
    "",
    {},
  );

  const handleChangeColumnFilterValue = (value, columnName) => {
    setColumnFiltersState({
      ...columnFilters,
      [columnName]: {
        ...columnFilters[columnName],
        value,
      },
    });
  };

  const handleColumnFiltersOptionChange = (newValue, columnName) => {
    const newFilters = getFilterObj(columnFiltersState, columnName, newValue);
    onColumnFiltersChange(newFilters);
  };

  return (
    <Box
      display="table-row"
      as="tr"
      flexDirection="row"
      justifyContent="center"
      width="100%"
      boxSizing="border-box"
      backgroundColor="white"
      fontWeight="500"
      borderRight="1px solid black"
      borderBottom="1px solid black"
      marginTop="5px"
    >
      {filterType === "global" ? (
        <Box as="td" colSpan="100%" alignContent="center" alignItems="center">
          <Box display="flex" flexFlow="row" width="100%">
            <Input
              width="100%"
              size="small"
              height="32px"
              placeholder="Szukaj"
              value={globalFilter}
              variant="outline"
              onChange={(e) => onGlobalFilterChange(e.target.value)}
            />
            {enableGlobalFilterOptions && <DatagridFilter {...filterOptions} />}
          </Box>
        </Box>
      ) : filterType === "column" ? (
        <>
          {(selectionMode === "single" || selectionMode === "multiple") && (
            <Box
              as="td"
              fontSize={fontSize}
              borderRight="1px solid black"
              width={selectColumnWidth}
            />
          )}
          {columnsWithFixedSizes.map((column, index) => {
            return (
              <FilterCell
                column={column}
                fontSize={fontSize}
                width={column.pxWidth < 0 ? 1 : column.pxWidth}
                columnFiltersState={getFilterState(
                  columnFiltersState,
                  column.name,
                )}
                handleChangeColumnFilterValue={handleChangeColumnFilterValue}
                handleColumnFiltersOptionChange={
                  handleColumnFiltersOptionChange
                }
                key={`header-cell-filter-${index}`}
              />
            );
          })}
        </>
      ) : null}
    </Box>
  );
};

DataGridFilterHeader.propTypes = {
  globalFilter: PropTypes.string,
  onGlobalFilterChange: PropTypes.func,
  filterOptions: PropTypes.shape({
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    columnFilters: PropTypes.object,
    onColumnFiltersChange: PropTypes.func,
  }).isRequired,
  enableGlobalFilter: PropTypes.bool,
  filterType: PropTypes.oneOf(["column", "global"]),
  columnDefinitions: PropTypes.array.isRequired,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  tableDimensions: PropTypes.object,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DataGridFilterHeader;
