import { getPartsRanges } from "./get-parts-ranges";
import { getSeparator } from "./get-separator";

export const goToNextPart = (e, format, currentPart, value) => {
  // const nextPart = getPartsRanges()
  const { usedSeparators } = getSeparator(format, value);
  const parts = getPartsRanges(value, usedSeparators);
  const nextPart = parts.find((p) => p.id === currentPart.id + 1);
  if (nextPart) e.target.setSelectionRange(nextPart.start, nextPart.end);
  else e.target.setSelectionRange(parts[0].start, parts[0].end);
};

export const goToPrevPart = (e, format, currentPart, value) => {
  const { usedSeparators } = getSeparator(format, value);
  const parts = getPartsRanges(value, usedSeparators);
  const prevPart = parts.find((p) => p.id === currentPart.id - 1);
  const lastPart = parts[parts.length - 1];
  if (prevPart) e.target.setSelectionRange(prevPart.start, prevPart.end);
  else e.target.setSelectionRange(lastPart.start, lastPart.end);
};
