import { useCallback, useMemo } from "react";
import { resolveActionProcess } from "../utils/resolve-actions";
import { getId } from "../utils/get-id";

export const useActions = (props, state, api) => {
  const { selectedItems, data } = state;
  const { designerMode, actions } = props;
  const { refreshData, addEmptyRow } = api;

  const resolvedActions = useMemo(() => {
    if (!designerMode && actions) {
      return resolveActionProcess(actions, props);
    }
    return [];
  }, [actions, designerMode, props]);

  const addParamsToEvents = useCallback(
    (actions) =>
      actions.map((a) => ({
        ...a,
        onClick: async () => {
          if (a.onClick && typeof a.onClick === "function") {
            await a.onClick(
              { name: "selection", value: JSON.stringify(selectedItems) },
              { name: "data", value: JSON.stringify(data.map(getId)) },
            );
          }
          if (a.refreshData) await refreshData();
        },
      })),
    [data, refreshData, selectedItems],
  );

  const addNewRowBtn = useCallback(
    (actions) => [
      {
        name: "addRowBtn",
        text: "Add row",
        isVisible: true,
        onClick: addEmptyRow,
      },
      ...actions,
    ],
    [addEmptyRow],
  );

  const mappedActions = useMemo(() => {
    const withEvents = addParamsToEvents(resolvedActions);

    if (props.enableAdding) {
      const withRowAddBtn = addNewRowBtn(withEvents);
      return withRowAddBtn;
    }

    return withEvents;
  }, [resolvedActions, addParamsToEvents, addNewRowBtn, props.enableAdding]);

  return { actions: mappedActions };
};
