import PropTypes from "prop-types";
import { Box, useDisclosure } from "@chakra-ui/react";
import ComponentPropertiesDesignerModal from "./component-properties-designer-modal";

const ComponentPropertiesDesignerPropEdit = ({ propValue, onChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = (...args) => {
    if (onChange) {
      onChange(...args);
    }
  };

  return (
    <>
      <ComponentPropertiesDesignerModal
        open={isOpen}
        onClose={onClose}
        initialSchemaObject={propValue}
        onSubmit={handleSubmit}
      />
      <Box
        display="flex"
        justifyContent={propValue ? "space-between" : "flex-end"}
        flexDirection="row"
        marginLeft="8px"
      >
        <Box
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {Array.isArray(propValue) && `Properties (${propValue.length})`}
        </Box>
        <button onClick={onOpen}>...</button>
      </Box>
    </>
  );
};

ComponentPropertiesDesignerPropEdit.propTypes = {
  propTypeObject: PropTypes.any,
  propValue: PropTypes.array,
  onChange: PropTypes.func,
};

export default ComponentPropertiesDesignerPropEdit;
