import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const BaseModal = ({
  title,
  width,
  height,
  onClose,
  onSubmit,
  open = false,
  children = null,
}) => {
  const handleClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const handleSubmit = (e) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        width={width ?? "80vw"}
        maxW={width ?? "80vw"}
        height={height ?? "80vh"}
        maxH={height ?? "80vh"}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

BaseModal.propTypes = {
  children: PropTypes.node,
  width: PropTypes.any,
  height: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

export default BaseModal;
