import PropTypes from "prop-types";
import DataGridHeaderCell from "./header-cell/header-cell";
import { Box, useDisclosure } from "@chakra-ui/react";
import LabeledCheckboxFormControl from "../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";
import { Draggable } from "@echo/draggable";
import { getColumns, selectColumnWidth } from "../utils/columns/column-utility";
import { useCallback } from "react";
import "../../../../../../../styles/index.css";
import { ColumnVisibilityManager } from "./column-visibility-manager";

const DataGridHeader = ({
  columnDefinitions,
  selectionMode,
  groupSelectionMode,
  changeMode,
  onSortChange,
  updateSorting,
  onFilterChange,
  tableDimensions,
  contentDimensions,
  onSizeChange,
  onOrderChange,
  onColumnHide,
  onColumnSettingsOpen,
  refreshDatagrid,
  fontSize,
  onColumnFiltersChange,
  sort = {},
  columnFilters = {},
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const visibleColumns = columnDefinitions.filter(
    (col) => col?.isVisible !== false && col?.name !== "__rowColor",
  );

  // const sortedColumns = visibleColumns.filter((c) => c.sort);

  const handleCheckboxClick = () => {
    if (changeMode) {
      changeMode();
    }
  };
  const visibleColumnWithFixedSizes = getColumns(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
    tableDimensions,
  );

  const handleColumnOrderChange = (oldOrder, newOrder) => {
    const oldIndex = columnDefinitions.findIndex(
      (col) => col === visibleColumns[oldOrder],
    );
    const newIndex = columnDefinitions.findIndex(
      (col) => col === visibleColumns[newOrder],
    );
    const newColumns = [
      ...columnDefinitions.filter((col) => col !== columnDefinitions[oldIndex]),
    ];
    newColumns.splice(newIndex, 0, columnDefinitions[oldIndex]);

    if (onOrderChange) {
      onOrderChange(newColumns);
    }
  };

  const handleColumnHide = useCallback(
    (visibleIndex) => {
      if (typeof onColumnHide === "function") {
        const index = columnDefinitions.findIndex(
          (col) => col === visibleColumns[visibleIndex],
        );
        onColumnHide(index);
      }
    },
    [columnDefinitions, onColumnHide, visibleColumns],
  );

  const handleColumnSort = (column, ctrlKey) => {
    onSortChange(column.name, ctrlKey);
    updateSorting(column.name, ctrlKey);
  };

  const headerCells = visibleColumnWithFixedSizes.map((columnDef, index) => {
    return (
      <Draggable
        key={`header-cell-${index}`}
        onDragEnd={(_, dragDetails) => {
          if (dragDetails.order >= 0) {
            handleColumnOrderChange(
              index,
              selectionMode === "single" || selectionMode === "multiple"
                ? dragDetails.order - 1
                : dragDetails.order,
            );
          } else {
            handleColumnHide(index);
          }
        }}
      >
        <DataGridHeaderCell
          key={`header-cell-${index}`}
          sort={sort[columnDef.name]}
          filter={(columnFilters || {})[columnDef.name]}
          onSortChange={(e) => handleColumnSort(columnDef, e.ctrlKey)}
          onFilterChange={onFilterChange}
          fontSize={fontSize}
          width={columnDef.pxWidth < 0 ? 1 : columnDef.pxWidth}
          contentDimensions={contentDimensions}
          onSizeChange={(newSize) => onSizeChange(newSize, columnDef)}
          enableResizer
          onColumnManagerOpen={onOpen}
          onColumnSettingsOpen={onColumnSettingsOpen}
          clearFilters={() => onColumnFiltersChange({})}
          refreshDatagrid={refreshDatagrid}
          tooltip
        >
          {columnDef.displayName || columnDef.name}
        </DataGridHeaderCell>
      </Draggable>
    );
  });

  return (
    <Box as="thead" boxSizing="border-box" display="block" paddingRight="17px">
      <ColumnVisibilityManager
        isOpen={isOpen}
        onClose={onClose}
        columns={columnDefinitions}
        onChange={onOrderChange}
      />
      <Box
        display="table-row"
        as="tr"
        borderBottom="1px solid black"
        boxSizing="border-box"
      >
        {selectionMode === "multiple" && (
          <DataGridHeaderCell
            fontSize={fontSize}
            small
            width={selectColumnWidth}
          >
            <LabeledCheckboxFormControl
              id="datagrid-header-checkbox"
              value={groupSelectionMode === "DESELECTION_EMPTY"}
              indeterminate={
                groupSelectionMode === "SELECTION_FILLED" ||
                groupSelectionMode === "DESELECTION_FILLED"
              }
              onChange={(_, e) => handleCheckboxClick(e)}
            />
          </DataGridHeaderCell>
        )}
        {selectionMode === "single" && (
          <DataGridHeaderCell
            fontSize={fontSize}
            small
            width={selectColumnWidth}
          ></DataGridHeaderCell>
        )}
        {headerCells}
      </Box>
    </Box>
  );
};

DataGridHeader.propTypes = {
  columnDefinitions: PropTypes.any.isRequired,
  sort: PropTypes.object,
  columnFilters: PropTypes.object,
  onSortChange: PropTypes.func,
  updateSorting: PropTypes.func,
  onFilterChange: PropTypes.func,
  groupSelectionMode: PropTypes.oneOf([
    "SELECTION_FILLED",
    "SELECTION_EMPTY",
    "DESELECTION_FILLED",
    "DESELECTION_EMPTY",
  ]),
  changeMode: PropTypes.func,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  tableDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  contentDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  onSizeChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onColumnHide: PropTypes.func,
  onColumnSettingsOpen: PropTypes.func,
  refreshDatagrid: PropTypes.func,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onColumnFiltersChange: PropTypes.func,
};

export default DataGridHeader;
