// @flow
import React, { useState, useMemo, useRef } from "react";
import { IconButton, Tr, useOutsideClick } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import type { Column, Record } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { CustomCheckbox } from "../checkbox/checkbox";
import { MdCheck } from "react-icons/md";
import { EditCell } from "../cell/edit-cell";
import { DisplayCell } from "../cell/display-cell";
import { getRecordValues, getResultData } from "../../utils/row-utils";
import { DATAGRID_BODY_ROW_CELL_TESTID } from "../../utils/test-ids";
import { getId } from "../../utils/get-id";

type RowProps = {
  record: Record,
  selectionMode?: "multiple",
  style: React.CSSProperties,
  onRowClick: () => void,
  onRowDoubleClick: () => void,
  onRowSelect: (e: any) => void,
};

export const Row = (props: RowProps): React.Node => {
  const { record, style, onRowClick, onRowDoubleClick, onRowSelect, columns } =
    props;
  const {
    columns: defaultColumns,
    selectionMode,
    selectedItems,
    actions: { onRowEdit },
    enableEdit,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
  } = useDatagridContext();

  const [editMode, setEditMode] = useState(false);
  const [focused, setFocused] = useState();
  const [rowState, setRowState] = useState(record);

  const values = useMemo(
    () => getRecordValues(record, columns),
    [record, columns],
  );

  const isChecked = (record, selectedItems) =>
    selectedItems?.find((item) => item == getId(record)) ? true : false;

  const handleCellValue = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => ({ ...prev, ...result }));
    }
  };

  const handleRowClick = () => {
    if (editMode) return;
    onRowClick();
  };

  const handleRowDoubleClick = () => {
    if (editMode) return;
    onRowDoubleClick();
  };

  const handleCellClick = (column: Column) => {
    if (!editMode && enableEdit && column.editable) {
      setFocused(column.order);
      setEditMode(true);
    }
  };

  const checkChanges = (oldState, newState) => {
    const keys = Object.keys(oldState);
    const boolArr = keys.map((k) => oldState[k] !== newState[k]);
    return boolArr.some((b) => b === true);
  };

  const handleRowEdit = () => {
    const isChanged = checkChanges(record, rowState);
    if (onRowEdit && enableEdit && isChanged) onRowEdit(rowState);
    setEditMode(false);
  };

  const rowRef = useRef();
  useOutsideClick({
    ref: rowRef,
    handler: () => {
      if (editMode && enableEdit) {
        handleRowEdit();
      }
    },
  });

  return (
    <Tr
      ref={rowRef}
      onClick={handleRowClick}
      onDoubleClick={handleRowDoubleClick}
      style={{
        ...style,
        borderBottom: "1px solid black",
        background: isChecked(record, selectedItems)
          ? "#89cff0"
          : style?.background,
      }}
      background={isChecked(record, selectedItems) ? "#89cff0" : ""}
    >
      {(selectionMode === "single" || selectionMode === "multiple") && (
        <Cell
          column={defaultColumns.find((col) => col.name === "checkbox")}
          width={defaultColumns.find((col) => col.name === "checkbox").width}
          key={"checkbox-body"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
        >
          {editMode ? (
            <IconButton
              icon={<MdCheck />}
              onClick={handleRowEdit}
              variant="outline"
            />
          ) : (
            <CustomCheckbox
              isChecked={isChecked(record, selectedItems)}
              onChange={onRowSelect}
              value={getId(record)}
            />
          )}
        </Cell>
      )}

      {rowState &&
        values.length > 0 &&
        values.map((val) => {
          return (editMode ||
            val.column.editType === "checkbox" ||
            val.column.type === "boolean") &&
            val.column.editable ? (
            <EditCell
              column={val.column}
              value={rowState[val.column.name]}
              row={rowState}
              onChange={
                val.column.editType === "checkbox"
                  ? async (value) => {
                      await handleCellValue(value, val.column);
                      onRowEdit({ ...rowState, [val.column.name]: value });
                    }
                  : handleCellValue
              }
              autoFocus={val.column.order === focused}
              key={`${val.column.name}-${record.id}-${rowState?.[val?.column?.name]?.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{ whiteSpace: cellWhiteSpace }}
            />
          ) : (
            <DisplayCell
              column={val.column}
              value={rowState[val.column.name]}
              onClick={handleCellClick}
              row={rowState}
              key={`${val.column.name}-${record.id}-${rowState?.[val?.column?.name]?.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{
                whiteSpace: cellWhiteSpace || "nowrap",
                fontSize: rowFontSize,
                padding: "0 5px",
              }}
              cellLinesNumber={cellLinesNumber}
            />
          );
        })}
    </Tr>
  );
};
