import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAsInternalState } from "../../../../../../../../../components/hooks/shared/use-as-internal-state";
import { Select } from "@chakra-ui/react";
import { uuidv4 } from "@echo/tools";
import { executeBlock } from "../../../../../../../../../process-executor/process-executor";

const ComboEditCell = ({ dataSource, value, onChange, context, row }) => {
  const labelId = uuidv4();
  const [availableValuesState, setAvailableValuesState] = useState([]);
  const values =
    availableValuesState && availableValuesState.length
      ? availableValuesState
      : [];
  const [internalValue, setInternalValue] = useAsInternalState(value, true, "");

  useEffect(() => {
    let isMounted = true;
    if (context && dataSource && dataSource.type === "PROCESS") {
      executeBlock(context, dataSource.id, dataSource.staticParams ?? {}, [row])
        .then((result) => {
          if (isMounted) {
            setAvailableValuesState(Array.isArray(result) ? result : [result]);
          }
        })
        .catch(() => {
          //showErrorToast(err);
        });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, context?.state]);

  const handleOnSelectChange = (e) => {
    const type = typeof Object.values(availableValuesState[0])[0];
    if (type === "string") {
      const newValue = e.target.value.toString();
      Promise.all([setInternalValue(newValue), handleOnChange(newValue)]);
    } else if (type === "number") {
      const newValue = Number(e.target.value);
      Promise.all([setInternalValue(newValue), handleOnChange(newValue)]);
    } else if (type === "boolean") {
      const newValue = Boolean(e.target.value);
      Promise.all([setInternalValue(newValue), handleOnChange(newValue)]);
    }
  };

  const handleOnChange = (newValue) => {
    const option = (values || []).find((option) => {
      const [optionValue] = Object.values(option);
      return optionValue.toString() === newValue.toString();
    });
    if (option) {
      const [, label] = Object.values(option);
      onChange(newValue, label);
    } else {
      onChange(newValue);
    }
  };

  const getValue = () => {
    const valueType = typeof internalValue;
    switch (valueType) {
      case "number":
      case "string":
        return internalValue.toString();
      case "boolean":
        return internalValue ? 1 : 0;
      default:
        return internalValue;
    }
  };

  return (
    <Select
      rootProps={{ className: value ? "combo-select-value" : "" }}
      width="100%"
      id={`select-label-${labelId}`}
      value={getValue()}
      onChange={handleOnSelectChange}
      placeholder={" "}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {values.map((option) => {
        const [value, label] = Object.values(option);
        return (
          <option key={`option-${value}`} value={value?.toString()}>
            {label || value}
          </option>
        );
      })}
    </Select>
  );
};

ComboEditCell.propTypes = {
  dataSource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    staticParams: PropTypes.object,
    type: PropTypes.oneOf(["PROCESS"]),
  }),
  value: PropTypes.any,
  context: PropTypes.any,
  onChange: PropTypes.func,
  row: PropTypes.any,
  //   options: PropTypes.shape({
  //     align: PropTypes.oneOf(['left', 'center', 'right']),
  //   }),
};

export default ComboEditCell;
