import { getEchoDefinedParent } from "../../components/echo-components/utils/definition-tree-utils/get-echo-defined-parent";
import { getProp } from "./actions/get-prop";

const getCrudAction = (base, component, name) => {
  if (!base && component[name]) {
    return component[name];
  } else {
    return component.crudActions;
  }
};

const handleGetReadAction = (
  base,
  component,
  callerId = null,
  callerGuid = null,
  callerNodeId = null,
) => {
  if (callerId && component.id !== callerId) {
    const callerEchoDefinedParent = getEchoDefinedParent(
      component,
      callerId,
      callerGuid,
      callerNodeId,
    );
    return getCrudAction(base, callerEchoDefinedParent.component, "crudRead");
  } else {
    return getCrudAction(base, component, "crudRead");
  }
};

export const createEchoComponentContext = (
  cmpState,
  cmpStateDispatcher,
  component,
  updateComponentDefinition,
  routerContext,
  userContext,
  page,
  modalComponentData,
  openModal,
  componentsRegistry,
  logSeverity,
) => {
  const functions = {
    setStateProp: (fullPath, propValue, spreadObject, caller) => {
      cmpStateDispatcher({
        type: "SET_VARIABLE",
        propPath: fullPath,
        propValue,
        spreadObject,
        caller,
      });
    },
    updateSource: (data) =>
      cmpStateDispatcher({
        type: "SET_FORM_SOURCE",
        propValue: data,
      }),
    getStateProp: (fullPath) => getProp(fullPath, cmpState),
    reload: () =>
      cmpStateDispatcher({
        type: "RELOAD",
      }),
    getModel: () => cmpState,
    getCreateAction: (base) => getCrudAction(base, component, "crudCreate"),
    getReadAction: (
      base,
      callerId = null,
      callerGuid = null,
      callerNodeId = null,
    ) =>
      handleGetReadAction(base, component, callerId, callerGuid, callerNodeId),
    getUpdateAction: (base) => getCrudAction(base, component, "crudUpdate"),
    getDeleteAction: (base) => getCrudAction(base, component, "crudDelete"),
    updateComponentDefinition,
    openModal,
  };

  return {
    functions,
    state: cmpState,
    routerContext,
    component,
    dispatchState: cmpStateDispatcher,
    userContext,
    page,
    modalComponentData,
    componentsRegistry,
    logSeverity,
  };
};
