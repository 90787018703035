import { executeBlock } from "../../../../../../../process-executor/process-executor";
import { DEFAULT_ZOOM, ZOOM_LEVELS } from "./variables";

export const getMapMarkers = async (dataSource, ctx) => {
  const res = await executeBlock(ctx, dataSource.id, {}, [], {});
  return res;
};

export const getZoomByAddressType = (type) => {
  if (!ZOOM_LEVELS[type]) return DEFAULT_ZOOM;
  return ZOOM_LEVELS[type];
};

const getPropertyKey = (obj, property) =>
  typeof obj === "object"
    ? Object.keys(obj).find((k) => k.toLowerCase() === property)
    : null;

const getPropertyValue = (obj, property) => {
  const key = getPropertyKey(obj, property);
  return obj[key];
};

const isObject = (obj) => typeof obj === "object";

export const getLatLng = (obj, test) => {
  if (!isObject(obj)) return null;
  const latKey = "lat";
  const lngKey = "lng";
  const hasLatProperty = getPropertyKey(obj, latKey);
  const hasLngProperty = getPropertyKey(obj, lngKey);

  if (hasLatProperty && hasLngProperty) {
    const lat = getPropertyValue(obj, latKey);
    const lng = getPropertyValue(obj, lngKey);
    return { lat, lng };
  }

  return Object.keys(obj)
    .filter((k) => k)
    .filter((k) => isObject(obj[k]))
    .map((k) => getLatLng(obj[k], test))
    .find((el) => getLatLng(el, test));
};
