import PropTypes from "prop-types";
import BooleanEditCell from "./boolean-edit-cell";
import InputNumericEditCell from "./input-numeric-edit-cell/input-numeric-edit-cell";
import ComboEditCell from "./combo-edit-cell";
import InputEditCell from "./input-edit-cell";
import DateEditCell from "./date-edit-cell.js/date-edit-cell";

const EditCell = (props) => {
  const { type, value } = props;
  switch (type || typeof value) {
    case "bigint":
    case "numeric":
      return <InputNumericEditCell {...props} />;
    case "string":
    case "input":
      return <InputEditCell {...props} />;
    case "combo":
      return <ComboEditCell {...props} />;
    case "checkbox":
      return <BooleanEditCell {...props} />;
    case "datetime":
      return <DateEditCell {...props} />;
    default:
      return null;
  }
};

EditCell.propTypes = {
  type: PropTypes.oneOf(["string", "boolean", "number", "bigint"]),
  dataSource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    staticParams: PropTypes.object,
    type: PropTypes.oneOf(["PROCESS"]),
  }),
  context: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    align: PropTypes.oneOf(["left", "center", "right"]),
  }),
  formatStr: PropTypes.any,
  row: PropTypes.any,
};

export default EditCell;
