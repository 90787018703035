import { Box, Button, Flex, HStack, Input } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useAsInternalState } from "../../../hooks/shared/use-as-internal-state";
import { MappingEditor } from "../../../shared/mapping-editor/mapping-editor";
import { useCallback, useState } from "react";
import { getMapperProps } from "./mapper-utils";

const ProcessLinkEdit = ({
  isOpen,
  onClose,
  processLink,
  onEdit,
  onDelete,
  processBlocks,
  stateProps = [],
}) => {
  const [linkModel, setLinkModel] = useAsInternalState(processLink, true, {});

  const [mappingsState, setMappingState] = useState({
    mappings: processLink.mappings || [],
    sourceProps: [
      ...stateProps.map((prop) => ({
        ...prop,
        locator: `$.__model.${prop.name}`,
      })),
      ...getMapperProps(
        processBlocks.find(
          (processBlock) =>
            processBlock.id === processLink.sourceProcessBlockId,
        ).flowSettings?.returnType?.properties,
      ),
    ],
    destinationProps: getMapperProps(
      processBlocks.find(
        (processBlock) =>
          processBlock.id === processLink.destinationProcessBlockId,
      ).flowSettings?.inParameters?.properties,
    ),
  });

  const handleMappingStateChange = useCallback(
    (newState) => {
      Promise.all([
        setMappingState(newState),
        setLinkModel({ ...linkModel, mappings: newState.mappings }),
      ]);
    },
    [linkModel, setLinkModel],
  );

  const handleOnSave = () => {
    if (onEdit) {
      Promise.all([onClose(), onEdit(linkModel)]);
    }
  };

  const handleOnDelete = () => {
    if (onDelete) {
      Promise.all([onClose(), onDelete(linkModel)]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="80vw"
        maxW="80vw"
        height="80vh"
        maxH="80vh"
        display="flex"
        flex="1"
      >
        <ModalHeader flex="none">Edit process link properties</ModalHeader>
        <ModalCloseButton flex="none" />
        <ModalBody flex="1" display="flex" overflow="hidden">
          <Flex flexFlow="row" gap="8px" minHeight={0} flex="1">
            <Box>
              <Input
                placeholder="Condition"
                value={linkModel.condition}
                onChange={(e) =>
                  setLinkModel({ ...linkModel, condition: e.target.value })
                }
              />
            </Box>
            <Box overflowY="auto" flex="1">
              <MappingEditor
                {...mappingsState}
                onChange={handleMappingStateChange}
              />
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose}>Close</Button>
            <Button colorScheme="red" onClick={handleOnDelete} variant="ghost">
              Delete
            </Button>
            <Button onClick={handleOnSave} variant="ghost">
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ProcessLinkEdit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  processLink: PropTypes.shape({
    mappings: PropTypes.array,
    sourceProcessBlockId: PropTypes.number,
    destinationProcessBlockId: PropTypes.number,
  }).isRequired,
  processBlocks: PropTypes.array,
  stateProps: PropTypes.array,
};

export default ProcessLinkEdit;
