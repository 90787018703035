import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useAsInternalState } from "../../../hooks/shared/use-as-internal-state";
import ProcessBlockEditTabs from "./process-block-edit/process-block-edit-tabs";
import ProcessBlockEditContext from "./process-block-edit/process-block-edit-context";
import GeneralSettings from "./process-block-edit/general-settings";
import EchoRouterContext from "../../../echo-router/echo-router-context";

const ProcessBlockEdit = ({
  isOpen,
  onClose,
  processBlock,
  onEdit,
  onDelete,
  modalProps,
}) => {
  const { openPage } = useContext(EchoRouterContext);
  const [processBlockModel, setProcessBlockModel] = useAsInternalState(
    processBlock,
    true,
    null,
  );

  const handleClose = (...args) => {
    if (onClose) {
      onClose(...args);
    }
  };

  const handleOnSave = (...args) => {
    if (onEdit) {
      Promise.all([onEdit(processBlockModel), handleClose(...args)]);
    }
  };

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(processBlock);
    }
  };

  const processBlockEditContext = useMemo(
    () => ({
      model: processBlockModel,
      updateModel: setProcessBlockModel,
    }),
    [processBlockModel, setProcessBlockModel],
  );

  return (
    <ProcessBlockEditContext.Provider value={processBlockEditContext}>
      {processBlockModel && (
        <Modal
          isCentered
          className="disable-ppz-drag"
          {...modalProps}
          isOpen={isOpen}
          closeOnEsc={false}
          onClose={handleClose}
        >
          <ModalOverlay />
          <ModalContent
            margin={0}
            width="90%"
            maxW="90%"
            height="90%"
            maxH="90%"
          >
            <ModalHeader className="disable-ppz-drag">
              {processBlock?.relatedBlock?.name
                ? `${processBlock?.relatedBlock?.name} - `
                : ""}
              Edit process block properties
            </ModalHeader>
            <ModalCloseButton className="disable-ppz-drag" />
            <ModalBody className="disable-ppz-drag" minHeight={0}>
              <HStack minHeight={0} height="100%">
                <GeneralSettings onDelete={handleOnDelete} />
                <Divider orientation="vertical" />
                <ProcessBlockEditTabs />
              </HStack>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button
                  onClick={() =>
                    openPage(
                      `/diagramDesigner/${processBlock.relatedBlock.id}`,
                      null,
                      `${processBlock.relatedBlock.name} - Process/Block designer`,
                      true,
                    )
                  }
                >
                  Edit diagram
                </Button>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleOnSave} variant="ghost">
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ProcessBlockEditContext.Provider>
  );
};

ProcessBlockEdit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onEdit: PropTypes.func,
  processBlock: PropTypes.shape({
    body: PropTypes.string.isRequired,
    flowSettings: PropTypes.shape({
      inParameters: PropTypes.object,
      returnType: PropTypes.object,
    }).isRequired,
    relatedBlock: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.any,
      blocks: PropTypes.array,
    }),
    isAsynchronous: PropTypes.bool,
  }).isRequired,
  modalProps: PropTypes.any,
  onDelete: PropTypes.func.isRequired,
};

export default ProcessBlockEdit;
