//@flow
import React from "react";
import { useEffect, useState } from "react";
import type { Column, ColumnType } from "../../../datagrid-v2";
import { InputEditCell } from "./input-edit-cell";
import { InputDateEditCell } from "./input-date-edit-cell";
import { InputNumericEditCell } from "./input-numeric-edit-cell";
import { BoolEditCell } from "./bool-edit-cell";
import { ComboEditCell } from "./combo-edit-cell";

export type TypedEditCellProps = {
  column: Column,
  autoFocus: boolean,
  onChange: (value: any) => void,
  value: any,
  row: Object,
};

export const TypedEditCell = (props: TypedEditCellProps): React.Node => {
  const { column, row, ...inputProps } = props;
  const { type, editType, editDataSource, format } = column;

  const [condition, setCondition] = useState<ColumnType | void>();
  const [options, setOptions] = useState();

  useEffect(() => {
    Promise.resolve(
      typeof editType === "function"
        ? editType(row)
        : editType || type || "string",
    )
      .then(setCondition)
      .catch((err) => console.error(err));
  }, [editType, type]);

  useEffect(() => {
    if (condition === "combo" && typeof editDataSource === "function") {
      editDataSource(row)
        .then(setOptions)
        .catch((err) => console.error(err));
    }
  }, [condition]);

  switch (condition) {
    case "numeric":
    case "number":
      return <InputNumericEditCell format={format} {...inputProps} />;
    case "date":
    case "datetime":
      return <InputDateEditCell dateFormat={format} {...inputProps} />;
    case "boolean":
    case "checkbox":
      return <BoolEditCell {...inputProps} />;
    case "combo":
      return <ComboEditCell options={options} {...inputProps} />;
    case "string":
      return <InputEditCell {...inputProps} />;
    default:
      null;
  }
};
