import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Button,
  Input,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import EchoStateValueCell from "../../../../../properties/component-properties-table/echo-state-value-cell";
import EchoInputStateValueCell from "../../../../../properties/component-properties-table/echo-input-state-value-cell";
import EchoBoolValueCell from "../../../../../properties/properties-table/value-cell/echo-bool-value-cell";
import EchoComboValueCell from "../../../../../properties/properties-table/value-cell/echo-combo-value-cell";
import PermissionEditorEditCell from "../../../../../permission-editor/permission-editor-edit-cell";
import PropertiesTable from "../../../../../properties/properties-table/properties-table";
import { getSetting } from "../../../../../../../services/user-services";
import UserContext from "../../../../../../../user-context";
import { UserRoleSelect } from "./user-role-select";

export const ColumnsEditor = ({
  open,
  onClose,
  columns,
  onSave,
  title,
  actions,
  containerRef,
  propTypes,
  componentId,
  propertiesSearch,
  settingsKey,
  settingsId,
}) => {
  const { userId, contextId, permissions } = useContext(UserContext);
  const roleId = (permissions.length && permissions[0].role.id) || null;
  const [state, setState] = useState([]);
  const [activeElement, setActiveElement] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Array.isArray(columns) && columns !== null) {
      Promise.all([setState(columns), setActiveElement(null)]);
    } else {
      Promise.all([setState([]), setActiveElement(null)]);
    }
  }, [columns]);

  const customTypes = [
    {
      name: "processSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "inputSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoInputStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "boolSelect",
      component: ({ propName, propValue, onChange }) => (
        <EchoBoolValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "comboSelect",
      component: ({ propValue, onChange, options, propType }) => {
        return (
          <EchoComboValueCell
            propValue={propValue}
            propType={propType}
            onChange={(selection) => onChange(selection || null)}
            options={options}
            enableProcessSelect
          />
        );
      },
    },
    {
      name: "permissionEditorPropSelect",
      component: ({ propValue, onChange, model }) => (
        <PermissionEditorEditCell
          propValue={propValue}
          propTypes={[{ propName: "isVisible" }]}
          onChange={onChange}
          designerPortalRef={containerRef}
          componentId={componentId}
          model={model}
        />
      ),
    },
  ];

  const handleOnClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const handleOnClickSave = useCallback(
    (state, e) => {
      let newState = [...state];
      if (activeElement) {
        newState = newState.map((el, idx) =>
          idx === activeElement.index ? activeElement.element : el,
        );
      }
      Promise.all([
        onSave(newState, contextId || userId, roleId),
        handleOnClose(e),
        setState([]),
        setActiveElement(null),
        toast.success("Successfully saved"),
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, roleId, state, activeElement],
  );

  const handleOnClick = (element, index, prevIndex) => {
    if (!activeElement) {
      setActiveElement({ index, element });
    } else {
      const newState = [...state];

      const stateWithUpdatedElement = newState.map((el, idx) =>
        prevIndex && idx === prevIndex ? activeElement.element : el,
      );

      Promise.all([
        setState(stateWithUpdatedElement),
        setActiveElement({ index, element }),
      ]);
    }
  };

  const handleModalClose = (e) => {
    handleOnClose(e);
    setActiveElement(null);
  };

  const [searchValue, setSearchValue] = useState("");

  const filteredState = useMemo(() => {
    if (!propertiesSearch) return state;
    if (!searchValue || searchValue === "") return state;
    return state.filter((el) =>
      el.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [propertiesSearch, searchValue, state]);

  const changeSettings = useCallback(
    async (user, role) => {
      setActiveElement();
      setIsLoading(true);

      try {
        const settings = await getSetting(user, role, settingsKey);
        const columns = JSON.parse(settings.value);
        setState(columns);
        toast.info("Settings updated.");
      } catch (e) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [settingsKey],
  );

  const saveSettings = useCallback(
    async (userId, roleId, type, company) => {
      let newState = [...state];
      if (activeElement) {
        newState = newState.map((el) =>
          el.name === activeElement.element.name ? activeElement.element : el,
        );
      }
      try {
        await onSave(newState, userId, roleId, type, company);
        toast.success("Successfully saved");
      } catch (e) {
        toast.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsId, settingsKey, state, activeElement],
  );

  return (
    <Modal
      isOpen={open}
      isCentered
      portalProps={{ containerRef }}
      onClose={handleModalClose}
    >
      <ModalOverlay />
      <ModalContent margin={0} width="90%" maxW="90%" height="90%" maxH="90%">
        <ModalHeader display="flex" flexDirection="row" gap="50px">
          <Text>{title}</Text>
          <UserRoleSelect
            changeSettings={changeSettings}
            settingsKey={settingsKey}
            saveSettings={saveSettings}
          />
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minHeight: 0,
          }}
        >
          {propertiesSearch && (
            <Input
              placeholder="Search table..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              width="fit-content"
              padding="10px 10px"
            />
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: 0,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              width="30%"
            >
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <List
                  border="1px solid black"
                  height="100%"
                  width="100%"
                  variant={"standard"}
                  overflowY={"auto"}
                >
                  {filteredState.map((element, index) => (
                    <ListItem
                      key={`row-cell-${index}`}
                      onClick={() =>
                        handleOnClick(element, index, activeElement?.index)
                      }
                      style={{
                        padding: "1px 8px",
                        cursor: "pointer",
                        borderBottom: "1px solid black",
                        fontWeight:
                          activeElement?.index === index ? "bolder" : undefined,
                      }}
                    >
                      {element.name}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Box style={{ width: "70%" }}>
              {activeElement?.element && (
                <PropertiesTable
                  options={propTypes}
                  model={activeElement.element}
                  onModelChange={(newColumnDef) =>
                    setActiveElement({
                      ...activeElement,
                      element: newColumnDef,
                    })
                  }
                  customTypes={customTypes}
                />
              )}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          {actions?.map((action) => (
            <Button
              key={action.name}
              mr={3}
              {...action.buttonProps}
              onClick={(e) => {
                const newState = [...state];
                if (activeElement) {
                  newState[activeElement.index] = activeElement.element;
                }
                action.action ? action.action(newState, e) : undefined;
              }}
            >
              {action.name}
            </Button>
          )) || null}
          <Button
            colorScheme="blue"
            mr={3}
            onClick={(e) => handleOnClickSave(state, e)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ColumnsEditor.propTypes = {
  isLoading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  open: PropTypes.any,
  onClose: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      buttonProps: PropTypes.object,
    }),
  ),
  containerRef: PropTypes.object,
  componentId: PropTypes.number,
  propTypes: PropTypes.any,
  propertiesSearch: PropTypes.bool,
  settingsKey: PropTypes.string,
  settingsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
