import React from "react";
import PropTypes from "prop-types";
import EchoComponent from "../../../../echo-component";
import { useComponentDefinition } from "../hooks/use-component-definition";
import { Box } from "@chakra-ui/react";

// For old context
const addContextProxy = (context, stateToReplace, updateState) => {
  const handleUpdateState = (newState) => {
    if (updateState && typeof updateState === "function") {
      updateState(newState.formSource);
    }
  };

  const state = { formSource: stateToReplace, __mounted: true };

  const { functions, routerContext, component, userContext, page } = context;

  const newFunctions = {
    ...functions,
    setStateProp: (fullPath, propValue) => {
      const propName = fullPath.split(".").slice(-1)[0];

      handleUpdateState({
        ...state,
        formSource: { ...state.formSource, [propName]: propValue },
      });
    },
    updateSource: (data) => handleUpdateState(data),
    getStateProp: (fullPath) => {
      const propName = fullPath.split(".").slice(-1)[0];
      return state.formSource ? state.formSource[propName] : undefined;
    },
    getModel: () => state,
  };

  return {
    functions: newFunctions,
    state,
    routerContext,
    component,
    dispatchState: handleUpdateState,
    userContext,
    page,
  };
};

const PropertiesComponent = ({
  componentId,
  context,
  state,
  onStateChange,
}) => {
  const component = useComponentDefinition(componentId);

  const proxyContext = addContextProxy(context, state, onStateChange);

  if (component) {
    return (
      <EchoComponent
        source="!?echo-defined"
        styleOptions={component.styleOptions}
        context={proxyContext}
        componentProps={{
          ...component.componentProps,
          context: proxyContext,
        }}
        systemProps={{
          id: component?.id,
          guid: component?.guid,
          nodeId: component?.nodeId,
          designerMode: false,
          context: proxyContext,
          childs: [],
          component,
          path: "",
        }}
      />
    );
  } else {
    return <Box>Loading...</Box>;
  }
};

PropertiesComponent.propTypes = {
  componentId: PropTypes.number.isRequired,
  state: PropTypes.object,
  onStateChange: PropTypes.func,

  context: PropTypes.any,
};

export default PropertiesComponent;
