import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getBlockGroupsByTag } from "../../../../services/block-groups-service";
import ParamsSetting from "../../params-setting/params-settings";
import DataGrid from "../../echo-components/base-components/components/datagrid-component/datagrid.component";
import { Box } from "@chakra-ui/react";

const columns = [
  {
    name: "name",
  },
  {
    name: "tags",
  },
];

const BlockGroupSelector = ({ onChange, tag, value }) => {
  const [source, setSource] = useState([]);

  useEffect(() => {
    getBlockGroupsByTag(tag)
      .then(setSource)
      .catch(() =>
        toast.error("Error. Error occurred while loading groups list"),
      );
  }, [tag]);

  const handleOnModelChange = (newModel) => {
    if (onChange) {
      onChange({ ...value, staticParams: newModel });
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        minHeight: 0,
        marginBottom: "68px",
      }}
    >
      <Box
        style={{ width: "50%", flex: 1 }}
        border="1px solid var(--chakra-colors-gray-200);"
      >
        <DataGrid
          objectKey="BLOCK_GROUP_SELECTOR"
          columns={columns}
          value={source}
          pageSize={20}
          onRowClick={(row) =>
            onChange({
              ...row,
              staticParams: !value.id ? value.staticParams : row.staticParams,
            })
          }
        />
      </Box>
      <Box
        border="1px solid var(--chakra-colors-gray-200);"
        style={{ width: "50%", overflow: "hidden" }}
      >
        {value && value?.name && value?.id && (
          <>
            <Box padding="0px 20px" fontWeight="bold">
              {value?.name}
            </Box>
            <ParamsSetting
              parametersSchema={
                source?.find((s) => s.id === value?.id)?.groupParams
              }
              model={value?.staticParams ?? {}}
              onUpdate={handleOnModelChange}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

BlockGroupSelector.propTypes = {
  value: PropTypes.any,
  tag: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

export default BlockGroupSelector;
