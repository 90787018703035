import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { Input } from "@echo/ui";
import { useEffect, useMemo, useCallback } from "react";
import { getDefinition } from "./definition-loader";
import { generateNumeration } from "./generate-numeration";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import { showErrorToast } from "../../../../echo-error-toast";

const NumeratorComponent = ({
  variant,
  type,
  name,
  value,
  rootProps,
  dataSource,
  numeratorDefinitionId,
  context,
  className = "",
  onChange = shimFunctions.shimFunction1,
  label = "",
  required = false,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const initialState = useMemo(
    () => context?.state?.initialFormSource,
    [context?.state],
  );

  const initialValue = useMemo(() => {
    if (!initialState || !name) return null;
    if (!initialState[name]) return null;

    return initialState[name];
  }, [initialState, name]);

  const state = useMemo(() => context?.state?.formSource, [context?.state]);
  const isMounted = useMemo(() => context?.state?.__mounted, [context?.state]);

  const handleNumeration = useCallback(async () => {
    try {
      const res = await getDefinition({
        numeratorDefinitionId,
        dataSource,
        context,
      });

      if (res.length) {
        const def = res[0];
        const numeration = generateNumeration(state, def);
        setInputValue(numeration);
      }
    } catch (e) {
      showErrorToast(e);
    }
  }, [context, dataSource, numeratorDefinitionId, setInputValue, state]);

  useEffect(() => {
    if (isMounted && state && dataSource && dataSource.id && !initialValue) {
      handleNumeration();
    }
  }, [isMounted, handleNumeration, dataSource, initialValue, state]);

  return (
    <Input
      {...rootProps}
      className={className}
      type={type}
      variant={variant}
      value={inputValue ?? ""}
      label={label}
      disabled={disabled}
      onChange={() => {}}
      required={required}
      isReadOnly
    />
  );
};

NumeratorComponent.propTypes = {
  numeratorDefinitionId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  dataSource: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.funcOf(PropTypes.string),
  className: PropTypes.string,
  rootProps: PropTypes.any,
  variant: PropTypes.oneOf([""]),
  type: PropTypes.oneOf(["text"]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  context: PropTypes.any,
};

export default withBaseComponent(NumeratorComponent);
