import { List } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CustomListItem } from "./list-item";

export const ItemsList = (props) => {
  const { items, deleteItem, onItemChange, propertyTypes, inputTypes } = props;

  return (
    <List
      width="100%"
      display="flex"
      overflowX="scroll"
      padding="10px 0"
      gap="10px"
    >
      {items.map((i, idx) => (
        <CustomListItem
          {...i}
          onChange={(i) => onItemChange(i, idx)}
          deleteItem={(i) => deleteItem(i, idx)}
          propertyTypes={propertyTypes}
          inputTypes={inputTypes}
          key={idx}
        />
      ))}
    </List>
  );
};

ItemsList.propTypes = {
  items: PropTypes.array,
  onItemChange: PropTypes.func,
  deleteItem: PropTypes.func,
  propertyTypes: PropTypes.array,
  inputTypes: PropTypes.array,
};
