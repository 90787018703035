import React from "react";
import { Button } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { useInputContext } from "../hooks/use-input-context";

export const StepperBtn = (props) => {
  const { type, onClick, isDisabled, tabIndex } = props;
  const { inputRef } = useInputContext();

  const handleClick = (e) => {
    e.preventDefault();
    if (document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
    onClick();
  };

  return (
    <Button
      aria-label={type}
      variant="unstyled"
      size="xs"
      border="none !important"
      padding="0 !important"
      margin="0 !important"
      onMouseDown={handleClick}
      display="flex !important"
      alignItems="center !important"
      justifyContent="center !important"
      height="50% !important"
      borderRadius="0 !important"
      isDisabled={isDisabled}
      _focus={{
        background: "grey.500",
      }}
      tabIndex={tabIndex}
      data-testid={
        type === "increment"
          ? "number-input-stepper-increment-test"
          : "number-input-stepper-decrement-test"
      }
    >
      {type === "increment" ? (
        <MdOutlineArrowDropUp
          style={{
            padding: "0",
            margin: "0",
          }}
        />
      ) : (
        <MdOutlineArrowDropDown
          style={{
            padding: "0",
            margin: "0",
          }}
        />
      )}
    </Button>
  );
};

StepperBtn.propTypes = {
  type: PropTypes.oneOf(["increment", "decrement"]),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
