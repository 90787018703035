import { useContext, useEffect, useState } from "react";
import { getComponent } from "../../../services/components-service";
import { createEchoComponentContext } from "../../../echo-component/context/create-echo-component-context";
import EchoRouterContext from "../../echo-router/echo-router-context";
import UserContext from "../../../user-context";
import { usePage } from "../../echo-router/hooks/use-page";
// import { useComponentRegistry } from "../../../components-registry/hooks/use-components-registry";
import { LogContext } from "../../../logger/log-context";
import { ERROR_SEVERITY } from "../../../logger/logger";

export const useEchoComponent = (
  id,
  componentState,
  componentStateDispatch,
  options = {},
  modalComponentData,
  openModal,
  componentsRegistry,
) => {
  const [component, setComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const routerContext = useContext(EchoRouterContext);
  const userContext = useContext(UserContext);
  // const componentRegistry = useComponentRegistry();
  const page = usePage();

  const logContext = useContext(LogContext);

  useEffect(() => {
    let mounted = true;
    getComponent({ id })
      .then((result) => {
        if (mounted) {
          Promise.all([setComponent(result), setIsLoading(result === null)]);
        }
      })
      .catch((err) => {
        window.console.error(err);
        if (options.onError) {
          options.onError(err);
        }
      });

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const context = createEchoComponentContext(
    componentState,
    componentStateDispatch,
    component,
    setComponent,
    routerContext,
    userContext,
    page,
    modalComponentData,
    openModal,
    componentsRegistry,
    logContext.logSeverity || ERROR_SEVERITY.ERROR,
  );

  return [
    {
      source: "!?echo-defined",
      styleOptions: component?.styleOptions,
      systemProps: {
        id: component?.id,
        guid: component?.guid,
        nodeId: component?.nodeId,
        designerMode: false,
        state: componentState,
        stateDispatch: componentStateDispatch,
        context,
        childs: [],
        component,
        path: "",
      },
    },
    isLoading,
    component,
    context,
    setComponent,
    userContext.isAdministrator,
  ];
};
