import { Box } from "@chakra-ui/react";
import { useContext, useState } from "react";
import UserContext from "../../user-context";
import "../../styles/login-page.css";
import { checkUser, signIn as apiSignIn } from "../../services/login-service";
import { jwtDecode } from "jwt-decode";
import LoginAuthStep from "./login-steps/login-auth-step";
import LoginCompanyStep from "./login-steps/login-company-step";

const LoginPage = () => {
  const { signIn } = useContext(UserContext);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginButtonPressed, setLoginButtonPressed] = useState(false);
  const [errorString, setErrorString] = useState("");
  const [countFalsePass, setCountFalsePass] = useState(0);
  const [stepNumber, setStepNumber] = useState(0);
  const [companies, setCompanies] = useState([]);

  const handleLogin = (login, password, companyId, groupId) =>
    apiSignIn(login, password, companyId, groupId)
      .then((token) => {
        window.localStorage.setItem("blocks", "[]");
        setCountFalsePass(0);
        var tokenData = jwtDecode(token);
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(tokenData.exp);
        signIn(
          login,
          token,
          companyId,
          expirationDate,
          null,
          tokenData.id,
          parseInt(tokenData.menuId),
          tokenData.contextId,
          tokenData.contextLoginId,
          tokenData.isSuperuser === "True",
          tokenData.isSourceContextSuperuser === "True",
        );
      })
      .catch((err) => {
        setErrorString(err.message);
        setCountFalsePass(countFalsePass + 1);
      });

  const onFormSubmit = (login, password) =>
    checkUser(login, password)
      .then((userAuthData) => {
        setCountFalsePass(0);
        if (
          userAuthData &&
          userAuthData.companies &&
          Array.isArray(userAuthData.companies) &&
          userAuthData.companies.length > 0
        ) {
          if (userAuthData.companies.length === 1) {
            const companyId = userAuthData.companies[0].id.toString();
            const groupId = userAuthData.companies[0].group?.id;
            handleLogin(login, password, companyId, groupId);
          } else {
            setCompanies(userAuthData.companies);
            setStepNumber(stepNumber + 1);
          }
        } else {
          setErrorString("User is not assigned to the company.");
        }
      })
      .catch((err) => {
        setErrorString(err.message);
        setCountFalsePass(countFalsePass + 1);
      });

  const handleCompanySubmit = (company) => {
    handleLogin(
      login,
      password,
      company?.id?.toString(),
      company?.group?.id?.toString(),
    );
  };

  return (
    <Box className="login-form">
      <img
        width="400px"
        src="https://www.caldo-izolacja.pl/www/images/caldo-ogolne.png"
        alt="Caldo"
      />
      <form
        style={{ marginTop: "32px" }}
        autoComplete="off"
        onSubmit={(e) => {
          if (login && password) {
            onFormSubmit(login, password);
          }
          setLoginButtonPressed(true);
          e.preventDefault();
        }}
      >
        {stepNumber === 0 && (
          <LoginAuthStep
            show={stepNumber === 0}
            loginButtonPressed={loginButtonPressed}
            login={login}
            onLoginChange={setLogin}
            password={password}
            onPasswordChange={setPassword}
            errorString={errorString}
            onErrorStringChange={setErrorString}
            countFalsePass={countFalsePass}
          />
        )}
        {stepNumber === 1 && (
          <LoginCompanyStep
            show={stepNumber === 1}
            companies={companies}
            onSubmit={handleCompanySubmit}
            isInvalid={loginButtonPressed || errorString !== ""}
          />
        )}
      </form>
    </Box>
  );
};

export default LoginPage;
