import { format, isValid, toDate } from "date-fns";
import { pl } from "date-fns/locale";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const getDigitsFormat = (str) => {
  const regex = /^#(0{0,})[,.](0{0,})$/;
  const match = str.match(regex);
  return match
    ? {
        separator: str[match[0].indexOf(match[1]) + match[1].length || 1],
        minimumIntegerDigits: match[1].length || 1,
        minimumFractionDigits: match[2].length,
      }
    : {};
};

const getFormatParameter = (str) => {
  const keyValuePair = str.split(":");
  if (keyValuePair.length !== 2) return;
  const key = keyValuePair[0];
  const value = keyValuePair[1];
  // eslint-disable-next-line consistent-return
  return { [key]: value };
};

const formatNumber = (value, formatStr) => {
  if (formatStr) {
    const options = formatStr.split(";");
    let formatParameters = {};
    let valueFormated = "";
    let locale = "";
    let digitsSeparator;

    options.forEach((opt) => {
      if (opt.includes("#")) {
        const { separator, ...digitsFormat } = getDigitsFormat(opt);
        formatParameters = { ...formatParameters, ...digitsFormat };
        digitsSeparator = separator;
      }

      locale = opt.includes("-") ? opt : navigator.language;
      formatParameters = { ...formatParameters, ...getFormatParameter(opt) };
    });

    try {
      valueFormated = digitsSeparator
        ? Intl.NumberFormat(locale, formatParameters)
            .format(value)
            .replaceAll(",", digitsSeparator)
        : Intl.NumberFormat(locale, formatParameters).format(value);
    } catch (error) {
      valueFormated = value;
    }
    return valueFormated;
  } else {
    return value;
  }
};

const formatDate = (value, formatStr) => {
  const date = new Date(value);
  const options = { locale: pl };

  if (formatStr) {
    try {
      return format(date, formatStr, options);
    } catch {
      toast.error("Input date format is not valid.", {
        toastId: "dateFormat",
      });
      return format(date, "dd-MM-yyyy", options);
    }
  }
  return format(date, "dd-MM-yyyy", options);
};

const handleDisplayDate = (value, formatStr) => {
  if (!value) return "";
  return isValid(toDate(new Date(value)))
    ? formatDate(value, formatStr)
    : value;
};

const InputDisplayCell = ({ value, type, formatStr }) => {
  switch (type || value) {
    case "bigint":
    case "number":
      return formatNumber(value, formatStr);
    case "datetime":
      return handleDisplayDate(value, formatStr);
    case "string":
      return value; //isValid(parse('29/10/1989', 'P', new Date(), { locale: gb })); //isValid(parse(value, new Date()), { locale: pl }); //format(new Date(value), 'dd-MM-yyyy');
    default:
      return value;
  }
};

InputDisplayCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputDisplayCell;
