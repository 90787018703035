import { Box, IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FiCornerDownLeft, FiXCircle } from "react-icons/fi";

const ConfirmDialog = ({
  position,
  top,
  left,
  right,
  bottom,
  onCancel,
  onConfirm,
  confirmIcon = <FiXCircle color="red" />,
  cancelIcon = <FiCornerDownLeft />,
  width = "100%",
  height = "100%",
}) => {
  return (
    <Box
      position={position}
      backgroundColor="white"
      height={height}
      width={width}
      display="flex"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      //   boxSizing="border-box"
      justifyContent="space-between"
      gap="5px"
    >
      <IconButton onClick={onCancel} height="100%" flex="1" variant="outline">
        {cancelIcon}
      </IconButton>
      <IconButton onClick={onConfirm} height="100%" flex="1" variant="outline">
        {confirmIcon}
      </IconButton>
    </Box>
  );
};

ConfirmDialog.propTypes = {
  confirmIcon: PropTypes.node,
  cancelIcon: PropTypes.node,
  position: PropTypes.oneOf([
    "static",
    "relative",
    "fixed",
    "absolute",
    "sticky",
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmDialog;
