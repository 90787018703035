import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import CollapseButton from "./panel-components/collapse-button";
import { debounce } from "../../../../../utils/debounce/debounce";

const PanelComponent = ({
  position,
  onPositionChange,
  hide = true,
  displayName = null,
  onLabelButtonClick = () => {},
}) => {
  const nodeRef = useRef();
  const screenWidth = window.innerWidth;

  const [isDrag, setIsDrag] = useState(false);

  // eslint-disable-next-line consistent-return
  const handleOnDragStop = (event) => {
    // Don't care about y position
    if (event.x) {
      if (event.x < screenWidth / 2) {
        if (position !== "left") {
          onPositionChange("left");
          return false;
        }
      } else {
        if (position !== "right") {
          onPositionChange("right");
          return false;
        }
      }
    }

    // Execute setIsDrag at the end - after onClick event
    debounce(() => {
      setIsDrag(false);
    }, 300)();
  };

  const handleOnDrag = (event) => {
    const safeClickArea = 50;
    /* Prevent disable onClick when button is moved up to (safeClickArea)px in any direction
     * In this case layerX is deltaX, layerY is deltaY
     */
    if (
      Math.abs(event.layerX) > safeClickArea ||
      Math.abs(event.layerY) > safeClickArea
    ) {
      setIsDrag(true);
    }
  };

  const handleOnLabelButtonClick = () => {
    if (!isDrag) {
      onLabelButtonClick(!hide);
    }
  };

  return (
    <div style={{ marginBottom: "8px" }}>
      <Draggable
        nodeRef={nodeRef}
        disabled={!hide}
        handle={"button"}
        position={{ x: 0, y: 0 }}
        onDrag={handleOnDrag}
        onStop={handleOnDragStop}
      >
        <div ref={nodeRef} className="box no-cursor">
          <CollapseButton
            position={position}
            label={displayName}
            onClick={handleOnLabelButtonClick}
          />
        </div>
      </Draggable>
    </div>
  );
};

PanelComponent.propTypes = {
  displayName: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"]).isRequired,
  onPositionChange: PropTypes.func.isRequired,
  onLabelButtonClick: PropTypes.func,
  hide: PropTypes.bool,
};

export default PanelComponent;
