// @flow

import React, { createContext, useRef } from "react";
import { useDatagridContext } from "../hooks/useDatagridContext";

export const DragContext = createContext();

export const DragContextProvider = ({ children }) => {
  const {
    columns,
    actions: { onColumnOrderChange, onColumnVisibilityChange },
  } = useDatagridContext();

  const containerRef = useRef();
  const dragPos = useRef();

  const onDragOver = (e, columnIndex) => {
    e.preventDefault();
    dragPos.current = columnIndex;
  };

  const mapColumn = (column: Column, columnIndex: number, dragPos: number) => {
    if (column.order === columnIndex) {
      return { ...column, order: dragPos };
    }

    if (
      dragPos < columnIndex &&
      column.order >= dragPos &&
      column.order < columnIndex
    ) {
      return { ...column, order: column.order + 1 };
    }

    if (
      dragPos > columnIndex &&
      column.order <= dragPos &&
      column.order >= columnIndex
    ) {
      return { ...column, order: column.order - 1 };
    }

    return column;
  };

  const onDragEnd = (e, columnIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragPos.current === -1) {
      const newColumns = columns
        .map((c) => (c.order === columnIndex ? { ...c, isVisible: false } : c))
        .filter((col) => col.name !== "checkbox");

      onColumnVisibilityChange(newColumns);
      dragPos.current = null;
      return;
    }

    const newColumns = columns
      .map((c) => mapColumn(c, columnIndex, dragPos.current))
      .filter((c) => c.name !== "checkbox");

    onColumnOrderChange(newColumns);
    dragPos.current = null;
  };

  const contextValue = {
    onDragOver,
    onDragEnd,
    containerRef,
  };

  return (
    <DragContext.Provider value={contextValue}>{children}</DragContext.Provider>
  );
};
