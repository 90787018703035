import PropTypes from "prop-types";
import { SingleDatepicker } from "../../../../../../../../datepicker/single";
import { Box } from "@chakra-ui/react";

const DateEditCell = ({ value, onChange, formatStr }) => {
  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <SingleDatepicker
        name="date-input"
        date={value}
        onDateChange={onChange}
        dateTimeFormat={formatStr}
      />
    </Box>
  );
};

DateEditCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  formatStr: PropTypes.string,
};

export default DateEditCell;
