import PropTypes from "prop-types";
import { Box, Input, Stack } from "@chakra-ui/react";

const GeneralSettings = ({ model, updateModel }) => {
  const handleInputChange = (value, propName) => {
    updateModel({ ...model, [propName]: value });
  };

  return (
    <Box display="flex" flex="1 2 0" height="100%" padding="0px 8px">
      <Stack width="100%">
        <Box height="42px" display="flex" alignItems="center">
          General settings
        </Box>
        <Input
          placeholder={"Name"}
          width="100%"
          minWidth="120px"
          value={model.name}
          onChange={(e) => handleInputChange(e.target.value, "name")}
        />
        <Input
          placeholder={"Description"}
          value={model.description}
          width="100%"
          minWidth="120px"
          onChange={(e) => handleInputChange(e.target.value, "description")}
        />
        <Input
          placeholder={"Location"}
          width="100%"
          minWidth="120px"
          value={model.location}
          onChange={(e) => handleInputChange(e.target.value, "location")}
        />
      </Stack>
    </Box>
  );
};

GeneralSettings.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
  updateModel: PropTypes.func.isRequired,
};

export default GeneralSettings;
