import PropTypes from "prop-types";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { FiArrowDownLeft } from "react-icons/fi";
import LabeledCheckboxFormControl from "../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";

export const datagridFooterDataTypes = [
  { id: 0, label: "None", value: "none" },
  { id: 1, label: "Sum", value: "sum" },
  { id: 2, label: "Average", value: "average" },
  { id: 3, label: "Filled cells average", value: "filled-cells-average" },
];

const DatagridFooterDataTypeSelector = ({ value, onChange }) => {
  const handleOnChange = (selected, valueString) => {
    if (onChange && typeof onChange === "function" && valueString) {
      onChange(valueString, selected);
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton size="sm" variant="ghost">
          <FiArrowDownLeft size="16px" />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Opcje filtrowania</PopoverHeader>
        <PopoverBody>
          <Box display="flex" flexFlow="column" width="100%">
            {datagridFooterDataTypes.map((option) => (
              <LabeledCheckboxFormControl
                id={`filter-checkbox-${option.value}`}
                key={option.value}
                value={option.value === value}
                label={option.label}
                onChange={(e) => handleOnChange(e, option.value)}
              />
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DatagridFooterDataTypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatagridFooterDataTypeSelector;
