import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel,
} from "@chakra-ui/react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useCallback } from "react";

const ColumnVisibilityManager = ({ isOpen, onClose, columns, onChange }) => {
  const handleVisibilityChange = useCallback(
    (column) => {
      if (typeof onChange === "function") {
        const columnsShallowCopy = [...columns];
        const colIndex = columnsShallowCopy.findIndex(
          (searchColumn) => searchColumn === column,
        );
        columnsShallowCopy[colIndex].isVisible =
          !columnsShallowCopy[colIndex].isVisible;
        onChange(columnsShallowCopy);
      }
    },
    [columns, onChange],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage column visibility</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack flexWrap="wrap" shouldWrapChildren>
            {(columns || []).map((column, index) => (
              <Tag
                cursor="pointer"
                onClick={() => handleVisibilityChange(column)}
                size="lg"
                key={`${column.displayName}-${index}`}
                variant="subtle"
                colorScheme={column.isVisible ? "cyan" : "red"}
              >
                <TagLeftIcon
                  boxSize="12px"
                  as={column.isVisible ? FiPlus : FiMinus}
                />
                <TagLabel>{column.displayName || column.name}</TagLabel>
              </Tag>
            ))}
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ColumnVisibilityManager.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      isVisible: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func,
};

export default ColumnVisibilityManager;
