import PropTypes from "prop-types";
import LabeledCheckboxFormControl from "../../../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";

const BooleanDisplayCell = ({ value }) => {
  return (
    <LabeledCheckboxFormControl
      id="table-checkbox"
      isReadOnly
      defaultValue={value || false}
    />
  );
};

BooleanDisplayCell.propTypes = { value: PropTypes.bool };

export default BooleanDisplayCell;
