// @flow

import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { FilterOptionType } from "./filter-options";

export type FilterInputProps = {
  onChange: () => void,
  value: string,
  type: FilterOptionType,
};

export const FilterInput = (props: FilterInputProps) => {
  const { type, onChange, value } = props;
  const handleChange = (e) => onChange(e.target.value);

  return (
    <Input
      padding="2px 4px"
      variant="outline"
      size="xs"
      width="100%"
      value={value || ""}
      onChange={handleChange}
    />
  );
};
