import { executeBlock } from "../../../process-executor";

const hasValue = (value) => {
  switch (value) {
    case false:
    case "":
    case null:
    case undefined:
      return false;
    default:
      return true;
  }
};

const checkOption = (opt, value) => {
  const hasValueKey = Object.keys(opt).includes(
    (k) => k.toLowerCase() === "value",
  );

  if (hasValueKey) {
    const valueKey = Object.keys(opt).find((k) => k.toLowerCase() === "value");
    return opt[valueKey] === value;
  }

  return Object.values(opt)[0] === value;
};

export const getInvalidFields = async (fields, state) => {
  const res = await Promise.all(
    fields?.map(async (field) => {
      const {
        componentProps: { name },
      } = field;

      if (!hasValue(state[name])) return field;

      return null;
    }),
  );

  return res?.filter((el) => el);
};
