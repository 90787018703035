import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shimFunctions } from "../../../utils/shims/shims";
import { getEditorPropType } from "../permission-editor/prop-type";
import { useResolveProp } from "../../hooks/resolve-prop/use-resolve-prop";
import { showErrorToast } from "../echo-error-toast";
import { resolveDefaultValue } from "../../../utils/property/default-value";

const getIsRequired = (props) => {
  const { isRequired, required } = props;
  return isRequired ? isRequired : required ? required : false;
};

const getIsInvalid = (isRequired, value, isInvalid) => {
  if (isRequired && typeof value === "string") {
    if (value) {
      return false;
    }

    return true;
  }

  if (isInvalid) {
    return true;
  }

  return false;
};

const getComponentProps = (name, label, isRequired, value, props) => {
  const isInvalid = getIsInvalid(isRequired, value, props.isInvalid);

  return { ...props, isInvalid };
};

const baseComponentProps = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onFormCreateAction: PropTypes.func,
  onFormReadAction: PropTypes.func,
  onCancelHover: PropTypes.func,
  isReadOnly: PropTypes.bool,
  onDrop: PropTypes.func,
  style: PropTypes.object,
  nodeRef: PropTypes.object,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  renderCondition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  permissions: getEditorPropType(
    PropTypes.shape({
      propertyNameMap: PropTypes.string.isRequired,
      permissionCheckProcess: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    }),
  ),
};

const getRootProps = (props) => ({
  onMouseDown: props.onMouseDown,
  onMouseUp: props.onMouseUp,
  onTouchEnd: props.onTouchEnd,
  onTouchStart: props.onTouchStart,
  style: props.style,
  nodeRef: props.nodeRef,
  onClick: props.onClick,
  onMouseOver: props.onMouseOver,
  onMouseLeave: props.onMouseLeave,
});

export const withBaseComponent = (Component) => {
  const BaseComponent = (inputProps) => {
    const [renderConditionState, setRenderConditionState] = useState(null);
    const props = useMemo(
      () => ({
        ...inputProps,
        onClick: inputProps.onClick ?? shimFunctions.shimFunction1,
        onMouseOver: inputProps.onMouseOver ?? shimFunctions.shimFunction1,
        onMouseLeave: inputProps.onMouseLeave ?? shimFunctions.shimFunction1,
        onChange: inputProps.onChange ?? shimFunctions.shimFunction1,
        onFormCreateAction:
          inputProps.onFormCreateAction ?? shimFunctions.shimFunction1,
        onFormReadAction:
          inputProps.onFormReadAction ?? shimFunctions.shimFunction1,
        onCancelHover: inputProps.onCancelHover ?? shimFunctions.shimFunction1,
        onMouseUp: inputProps.onMouseUp ?? (() => {}),
        onMouseDown: inputProps.onMouseDown ?? (() => {}),
        onTouchEnd: inputProps.onTouchEnd ?? (() => {}),
        renderEffect: inputProps.renderEffect ?? (() => {}),
        renderCondition: inputProps.renderCondition ?? true,
        permissions: inputProps.permissions ?? [],
      }),
      [inputProps],
    );

    const { name, label, value, context, defaultValue, onChange } = props;
    const isRequiredValue = getIsRequired(props);
    const isRequiredState = useResolveProp(isRequiredValue, true);

    const isDefaultValueSet = useRef(false);

    useEffect(() => {
      let timeout;
      if (defaultValue && onChange && !isDefaultValueSet.current) {
        timeout = setTimeout(() => {
          Promise.all([
            resolveDefaultValue(value),
            resolveDefaultValue(defaultValue),
          ])
            .then(([resolvedValue, resolvedDefaultValue]) => {
              if (
                (!context?.state?.formSource?._id && resolvedValue === null) ||
                typeof resolvedValue === "undefined"
              ) {
                onChange(resolvedDefaultValue);
              }
            })
            .catch(showErrorToast)
            .finally(() => {
              isDefaultValueSet.current = true;
            });
        }, 1000);
      }

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const isActive =
      context?.routerContext?.getActivePage()?.key === context?.page?.key;

    const handleOnDrop = useCallback(
      (event) => {
        if (typeof event.preventDefault === "function") {
          event.preventDefault();
        }
        if (
          typeof event.stopPropagation === "function" &&
          typeof props.onDrop === "function"
        ) {
          event.stopPropagation();
        }

        const fileList = event?.dataTransfer?.files;
        if (fileList && fileList.length && typeof props.onDrop === "function") {
          const file = fileList[0];
          props.onDrop(file).catch(showErrorToast);
        }
      },
      [props],
    );

    const handleOnDragOver = useCallback((e) => {
      e.preventDefault();
    }, []);

    useEffect(() => {
      if (props?.renderCondition) {
        if (typeof props?.renderCondition === "function") {
          Promise.resolve(props?.renderCondition())
            .then((condition) => {
              if (
                Array.isArray(condition) &&
                condition.length > 0 &&
                typeof condition === "object"
              ) {
                const conditionObjectKeys = Object.keys(condition[0]);
                const conditionValue =
                  conditionObjectKeys.length > 0 &&
                  !!condition[0][conditionObjectKeys[0]];
                setRenderConditionState(!!conditionValue);
              } else {
                setRenderConditionState(!!condition);
              }
            })
            .catch(() => setRenderConditionState(true));
        } else {
          setRenderConditionState(props?.renderCondition || false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.renderCondition, renderConditionState]);

    const componentProps = useMemo(
      () =>
        getComponentProps(name, label, isRequiredState, value, props, isActive),
      [name, label, isRequiredState, value, props, isActive],
    );

    return (
      renderConditionState && (
        <Component
          rootProps={{
            ...getRootProps(props),
            onDrop: handleOnDrop,
            onDragOver: handleOnDragOver,
          }}
          {...componentProps}
        />
      )
    );
  };

  BaseComponent.propTypes = {
    ...(Component.propTypes ?? {}),
    ...baseComponentProps,
  };

  return BaseComponent;
};
