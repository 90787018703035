import { useCallback, useMemo, useState } from "react";

export const useSummary = (summary) => {
  const [summaryValues, setSummaryValues] = useState([]);

  const handleClearSummary = useCallback((c) => {
    setSummaryValues((prev) => prev.filter((el) => el.name !== c.name));
  }, []);

  const handleSummaryValue = useCallback(
    (c, type) => {
      if (!summary) return;

      const f = summary.find((el) => el.name === c.name);
      if (!f) return;

      setSummaryValues((prev) => [
        ...prev.filter((el) => el.name !== f.name),
        { name: f.name, value: f[type], type },
      ]);
    },
    [summary],
  );

  const summaryOptions = useMemo(() => {
    if (!summary) return { columns: [], actions: [] };
    const columns = summary.map((el) => el.name);
    const actions = [
      { label: "Clear", type: "clear", action: handleClearSummary },
      { label: "Sum", type: "sum", action: handleSummaryValue },
      { label: "Average", type: "avg", action: handleSummaryValue },
    ];

    return { columns, actions };
  }, [summary, handleClearSummary, handleSummaryValue]);

  return { summaryOptions, summaryValues };
};
