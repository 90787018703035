import { useEffect, useState } from "react";

export const useShowTooltip = (element) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(() => {
        if (element) {
          const scrollWidth = element.scrollWidth;
          const clientWidth = element.clientWidth;

          if (scrollWidth > clientWidth) setShowTooltip(true);
          else setShowTooltip(false);
        }
      });

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element && observer) {
          observer.unobserve(element);
        }
      };
    }

    return () => {};
  }, [element]);

  return { showTooltip };
};
