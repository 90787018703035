export const actionPropertyTypes = [
  {
    propName: "text",
    propType: "inputSelect",
  },
  {
    propName: "onClick",
    propType: "processSelect",
  },
  {
    propName: "refreshData",
    propType: "boolSelect",
  },
  {
    propName: "isVisible",
    propType: "boolSelect",
  },
  {
    propName: "style",
    propType: "styleEditor",
  },
];

export const columnPropertyTypes = [
  {
    propName: "formatType",
    propType: "combo",
    options: [
      { value: "datetime", label: "dateTime" },
      { value: "boolean", label: "boolean" },
      { value: "string", label: "string" },
      { value: "number", label: "numeric" },
    ],
  },
  {
    propName: "format",
    propType: "inputSelect",
  },
  {
    propName: "editable",
    propType: "boolSelect",
  },
  {
    propName: "editType",
    propType: "comboSelect",
    options: [
      { value: "datetime", label: "datetime" },
      { value: "checkbox", label: "boolean" },
      { value: "string", label: "string" },
      { value: "numeric", label: "numeric" },
      { value: "combo", label: "combo" },
    ],
  },
  {
    propName: "editDataSource",
    propType: "processSelect",
  },
  {
    propName: "onChangeEvent",
    propType: "processSelect",
  },
];
