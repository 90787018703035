import { checkExistingValue } from "../../../../services/validation-service";
import { showIsUniqueAlert } from "./is-unique-alert";
import { updateComponent } from "./set-fields-invalid";
import { showUniqueError } from "./show-error";

const fieldMapper = (el, state) => {
  const name = el.componentProps.name;
  const value = state[name];

  return { name, value };
};

const mapUniqueFields = (arr, state) => arr.map((el) => fieldMapper(el, state));

const getId = (obj) => {
  const idKey = Object.keys(obj).find((k) => k.toLowerCase() === "id");
  return obj[idKey];
};

export const checkUniqueFields = async (arr, state, ctx, alert) => {
  const { component } = ctx;
  const { relatedObjectId: objectId } = component;
  const docId = getId(state);

  const fields = mapUniqueFields(arr, state);

  const invalidFields = await checkExistingValue(objectId, docId, fields);

  const mappedInvalidFields = invalidFields.map((i1) =>
    arr.find((i2) => i1.name == i2.componentProps.name),
  );

  if (invalidFields.length > 0 && alert) {
    const alertQuestion =
      invalidFields.length > 1
        ? `Wartości w polach ${invalidFields.map((f) => f.name).join(", ")} już istnieją w bazie danych. Czy na pewno chcesz zapisać dokument?`
        : `Wartość w polu ${invalidFields[0].name} już istnieje w bazie danych. Czy na pewno chcesz zapisać dokument?`;

    const shouldSave = await showIsUniqueAlert(alertQuestion);

    if (shouldSave) return false;

    return true;
  } else if (mappedInvalidFields.length > 0) {
    arr.forEach(showUniqueError);
    updateComponent(component, mappedInvalidFields, ctx);
    return true;
  }

  return false;
};
