import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { useChildDnd } from "./use-child-dnd";
import { useRef } from "react";

const DesignerChild = ({
  children,
  id,
  freeze,
  onDrag,
  onStop,
  ...draggableProps
}) => {
  const posRef = useRef({ x: 0, y: 0 });
  const [isHover, ref] = useChildDnd(id, freeze);

  const handleDrag = (e, ui) => {
    posRef.current = {
      x: posRef.current.x + ui.deltaX,
      y: posRef.current.y + ui.deltaY,
    };

    onDrag(e);
  };

  const handleStop = (e, ui) => {
    posRef.current = {
      x: 0,
      y: 0,
    };

    onStop(e, ui);
  };

  return (
    <Draggable
      disabled={!isHover}
      position={posRef.current}
      nodeRef={ref}
      onDrag={handleDrag}
      onStop={handleStop}
      {...draggableProps}
    >
      {children(ref)}
    </Draggable>
  );
};

DesignerChild.propTypes = {
  id: PropTypes.number,
  children: PropTypes.func.isRequired,
  freeze: PropTypes.bool,
  disabled: PropTypes.bool,
  onStop: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  systemProps: PropTypes.object,
  styleOptions: PropTypes.object,
};

export default DesignerChild;
