import { toast } from "react-toastify";
import { executeBlock } from "../../../process-executor";
import { getModelFromResult } from "./get-model-from-result";
import { updateModel } from "./update-model";
import { getModelId } from "./get-id";

const getProcessParams = (ctx, staticParams, params, model) => ({
  staticParams: {
    ...staticParams,
    __model: model,
  },
  params: [
    ...(params?.filter((c) => c !== null) ?? []),
    { action: getModelId(model) ? "update" : "create" },
    { componentId: ctx?.component?.id },
    { isOpen: false },
  ],
});

export const executeProcess = async (
  ctx,
  staticParams,
  params,
  action,
  model,
  systemParams,
) => {
  const disableToast = staticParams.disableToast;
  const blockParams = getProcessParams(ctx, staticParams, params, model);
  const res = await executeBlock(
    ctx,
    action.id,
    blockParams.staticParams,
    blockParams.params,
    systemParams,
  );

  if (res) {
    if (!disableToast) {
      toast.success("Saved successfully.");
    }

    const resultModel = getModelFromResult(res);
    updateModel(resultModel, ctx);
    return { ...model, ...resultModel, useContextModel: true };
  }

  return model;
};
