const setFieldsInvalid = (component, fields, ctx) => {
  const { childrenComponents } = component;

  fields.forEach((field) => {
    if (childrenComponents && childrenComponents.length > 0) {
      childrenComponents.forEach((child) =>
        setFieldsInvalid(child, fields, ctx),
      );
    }

    if (component.componentProps.name === field.componentProps.name) {
      component.componentProps.isInvalid = true;
    }
  });
};

export const updateComponent = (component, fields, ctx) => {
  const {
    functions: { updateComponentDefinition, reload },
  } = ctx;
  setFieldsInvalid(component, fields);
  updateComponentDefinition(component);
  reload();
};
