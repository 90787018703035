// @flow

import React from 'react'

export const filterOptions = [
  { id: 0, label: "Zawiera", value: "contains" },
  { id: 1, label: "Nie zawiera", value: "notContains" },
  { id: 2, label: "Równe", value: "equal" },
  { id: 3, label: "Nierówne", value: "notEqual" },
  { id: 4, label: "Większe niż", value: "greaterThan" },
  { id: 5, label: "Większe lub równe", value: "greaterOrEqual" },
  { id: 6, label: "Mniejsze niż", value: "lowerThan" },
  { id: 7, label: "Mniejsze lub równe", value: "lowerOrEqual" },
  { id: 8, label: "Puste", value: "empty" },
  { id: 9, label: "Niepuste", value: "notEmpty" },
  { id: 10, label: "Pomiędzy", value: "between" },
  { id: 11, label: "Wyklucz zakres", value: "excludeRange" },
] as const;

export type FilterOptionType = typeof filterOptions[number]['value'] ;