import { useCallback, useContext, useMemo } from "react";
import { echoPortal } from "../../../../../../echo-portal/echo-portal";
import { getId } from "../utils/get-id";
import UserContext from "../../../../../../../user-context";
import QuestionAlert from "../../question-alert-component/question-alert.component";

export const useContextActions = (state, api) => {
  const { isSuperuser } = useContext(UserContext);
  const { settings, itemsCount, selectedItems } = state;
  const {
    getData,
    selectAll,
    unselectAll,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onNavPositionChange,
    onColumnsManagerOpen,
    onHeaderWhiteSpaceChange,
    onColumnsSettingsOpen,
    onFilterChange,
    refreshData,
  } = api;

  const { disableFilters, disableHeader, navPosition, headerWhiteSpace } =
    settings;

  const selectAllAction = useCallback(async () => {
    if (itemsCount > 3000) {
      const promise = () =>
        new Promise((resolve) =>
          echoPortal.render((container) => (
            <QuestionAlert
              open={true}
              header={"Zaznaczanie wszystkich wierszy."}
              question={`Próbujesz zaznaczyć ${itemsCount} rekordów. Czy na pewno chcesz kontynuować?`}
              alertMode={"Tak/Nie"}
              onAction={(type) => {
                container.unmount();
                resolve(type.toLowerCase());
              }}
            />
          )),
        );

      const res = await promise();
      if (res === "nie") return;
    }

    const { data } = await getData({ page: 0, itemsPerPage: itemsCount });
    selectAll(data.map((i) => getId(i)?.toString()));
  }, [itemsCount, getData, selectAll]);

  const selectAllRowsAction = useMemo(
    () => ({
      label:
        selectedItems.length === itemsCount
          ? `Odznacz wszystkie (${itemsCount} wierszy)`
          : `Zaznacz wszystkie (${itemsCount} wierszy)`,
      action:
        selectedItems.length === itemsCount ? unselectAll : selectAllAction,
    }),
    [itemsCount, selectedItems, unselectAll, selectAllAction],
  );

  const headerVisilibityAction = useMemo(
    () => ({
      label: `${disableHeader ? "Show" : "Hide"} header`,
      action: () => onHeaderVisibilityChange(!disableHeader),
    }),
    [disableHeader, onHeaderVisibilityChange],
  );

  const filtersVisibilityAction = useMemo(
    () => ({
      label: `${disableFilters ? "Show" : "Hide"} filters`,
      action: () => onFiltersVisibilityChange(!disableFilters),
    }),
    [onFiltersVisibilityChange, disableFilters],
  );

  const navPositionAction = useMemo(
    () => ({
      label: `Change navigation position: ${navPosition === "bottom" ? "top" : "bottom"}`,
      action: () =>
        onNavPositionChange(navPosition === "bottom" ? "top" : "bottom"),
    }),
    [navPosition, onNavPositionChange],
  );

  const columnsManagerAction = useMemo(
    () => ({
      label: "Manage columns",
      action: onColumnsManagerOpen,
      color: "red",
      position: ["header"],
    }),
    [onColumnsManagerOpen],
  );

  const headerWhiteSpaceAction = useMemo(
    () => ({
      label: `Change header wrap: ${headerWhiteSpace === "normal" ? "no wrap" : "wrap"}`,
      action: () =>
        onHeaderWhiteSpaceChange(
          headerWhiteSpace === "normal" ? "nowrap" : "normal",
        ),
      position: ["header"],
    }),
    [onHeaderWhiteSpaceChange, headerWhiteSpace],
  );

  const columnSettingsAction = useMemo(
    () => ({
      label: "Advanced columns settings",
      action: onColumnsSettingsOpen,
      position: ["header"],
    }),
    [onColumnsSettingsOpen],
  );

  const clearFiltersAction = useMemo(
    () => ({
      label: "Clear filters",
      action: () => onFilterChange([]),
      position: ["header"],
    }),

    [onFilterChange],
  );

  const refreshDataAction = useMemo(
    () => ({
      label: "Refresh data",
      action: refreshData,
      position: ["header"],
    }),
    [refreshData],
  );

  const contextActions = [
    columnsManagerAction,
    isSuperuser && columnSettingsAction,
    clearFiltersAction,
    refreshDataAction,
    headerVisilibityAction,
    filtersVisibilityAction,
    navPositionAction,
    headerWhiteSpaceAction,
  ];

  const selectionContextActions = [selectAllRowsAction];

  return { contextActions, selectionContextActions };
};
