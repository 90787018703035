import React, { createContext, useCallback, useEffect, useRef } from "react";

export const ScrollContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  const bodyRef = useRef();
  const headerRef = useRef();
  const footerRef = useRef();

  const setRightPadding = useCallback(() => {
    if (!headerRef.current || !footerRef.current || !bodyRef.current) return;
    const bodyOffsetWidth = bodyRef.current.offsetWidth;
    const bodyClientWidth = bodyRef.current.clientWidth;
    const scrollbarWidth = bodyOffsetWidth - bodyClientWidth;
    const headerPaddingRight = headerRef.current.style.paddingRight;
    const footerPaddingRight = footerRef.current.style.paddingRight;

    if (headerPaddingRight !== scrollbarWidth) {
      headerRef.current.style.paddingRight = `${scrollbarWidth}px`;
    }

    if (footerPaddingRight !== scrollbarWidth) {
      footerRef.current.style.paddingRight = `${scrollbarWidth}px`;
    }
  }, [bodyRef, headerRef, footerRef]);

  const setScrollLeft = useCallback(
    (val) => {
      if (!footerRef.current || !headerRef.current) return;
      headerRef.current.scrollLeft = val;
      footerRef.current.scrollLeft = val;
    },
    [footerRef, headerRef],
  );

  const onBodyScroll = useCallback(
    (e) => {
      if (!headerRef.current && !footerRef.current) return;
      const scrollLeft = e.target.scrollLeft;
      setScrollLeft(scrollLeft);
      setRightPadding();
    },
    [headerRef, footerRef],
  );

  useEffect(() => {
    if (!bodyRef.current || !headerRef.current || !footerRef.current) return;
    setRightPadding();
  }, [bodyRef, headerRef, footerRef]);

  useEffect(() => {
    if (headerRef.current && bodyRef.current) {
      setScrollLeft(bodyRef.current.scrollLeft);
    }
  }, [headerRef.current]);

  const contextValue = {
    bodyRef,
    headerRef,
    footerRef,
    onBodyScroll,
    setScrollLeft,
  };

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};
