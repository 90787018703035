import EditorComponent from "../../../../shared/properties/properties-table/cell-components/editor-component";
import PropTypes from "prop-types";
import { extractValue, parseValue } from "./template-util";

const EditorTab = ({ model, updateModel }) => {
  const type =
    model?.relatedBlock?.executeType === 4 ||
    (model?.relatedBlock?.executeType === 5 &&
      model?.relatedBlock?.type === 1) ||
    model?.relatedBlock?.executeType === 42
      ? "javascript"
      : "sql";

  const handleUpdate = (newBody) => {
    updateModel({ ...model, body: parseValue(newBody, type) });
  };

  return (
    <EditorComponent
      type={type}
      value={extractValue(model.body, type)}
      onChange={handleUpdate}
      options={{
        mode: type === "javascript" ? "text/javascript" : "text/x-pgsql",
        lineNumbers: true,
      }}
    />
  );
};

EditorTab.propTypes = {
  model: PropTypes.any.isRequired,
  updateModel: PropTypes.func.isRequired,
};

export default EditorTab;
