import React, { useMemo, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Box } from "@chakra-ui/react";
import { useRefDimensions } from "@echo/ui";
import PPZNavigation from "./components/ppz-navigation";
import PanPinchZoomDragContext from "./context/pan-pinch-zoom-drag-context";
import {
  initializePanPinchZoomDragState,
  panPinchZoomDragReducer,
} from "./state/pan-pinch-zoom-drag-reducer";

const PanPinchZoomDrag = ({
  children,
  toolboxComponent,
  customNavigation,
  ...props
}) => {
  const containerRef = useRef(null);
  const [componentDimensions] = useRefDimensions(containerRef);

  const [state, dispatchState] = useReducer(
    panPinchZoomDragReducer,
    initializePanPinchZoomDragState(),
  );

  const handleStateUpdate = (type) => (payload) =>
    dispatchState({ type, payload });

  const ppzContext = useMemo(
    () => ({
      toolboxState: state.toolbox,
      containerState: { ...state.container, ...componentDimensions },
      areaState: state.area,
      updateAreaState: handleStateUpdate("UPDATE_AREA"),
      updateToolboxState: handleStateUpdate("UPDATE_TOOLBOX"),
      updateContainerState: handleStateUpdate("UPDATE_CONTAINER"),
    }),
    [state.area, componentDimensions, state.container, state.toolbox],
  );

  return (
    <TransformWrapper
      initialScale={16}
      wheel={{ step: 0.01 }}
      maxScale={32}
      initialPositionX={0}
      initialPositionY={0}
      panning={{
        excluded: [
          "disable-ppz-pan",
          "rect",
          "line",
          "polygon",
          "circle",
          "path",
        ],
      }}
    >
      {(api) => {
        const scale = api.instance.transformState.scale / 16;
        const previousScale = api.instance.transformState.previousScale / 16;
        return (
          <PanPinchZoomDragContext.Provider
            value={{
              ...ppzContext,
              transformState: {
                ...api.instance.transformState,
                scale,
                previousScale,
              },
            }}
          >
            <Box
              overflow="hidden"
              position="relative"
              flex={1}
              display="flex"
              {...props}
            >
              {toolboxComponent}
              <TransformComponent>
                <Box
                  ref={containerRef}
                  transform={`translateZ(0) scale(0.0625)`}
                  transformOrigin="top left"
                  boxSizing="border-box"
                >
                  {children}
                </Box>
              </TransformComponent>
              <PPZNavigation customNavigation={customNavigation} api={api} />
            </Box>
          </PanPinchZoomDragContext.Provider>
        );
      }}
    </TransformWrapper>
  );
};

PanPinchZoomDrag.propTypes = {
  children: PropTypes.node.isRequired,
  onMouseOver: PropTypes.func,
  toolboxComponent: PropTypes.node,
  customNavigation: PropTypes.arrayOf(
    PropTypes.shape(
      {
        icon: PropTypes.node.isRequired,
        onClick: PropTypes.func,
        key: PropTypes.string.isRequired,
      }.isRequired,
    ),
  ),
};

export default PanPinchZoomDrag;
