import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { showErrorToast } from "../../../../../../../../../shared/echo-error-toast";
import PropTypes from "prop-types";
import { forwardRef, useMemo } from "react";
import { useNumeric } from "./hooks";

const validateValue = (value) =>
  /^[-+]{0,1}[0-9]*([,.]{1}[0-9]*){0,1}/g.test(value?.toString() || "0");

const InputNumericEditCell = forwardRef(
  ({ value, onChange, options, formatStr }, ref) => {
    if (!validateValue(value)) {
      showErrorToast({
        reasonTitle: "Error. Input value is not valid",
        location: "Numeric edit cell",
        shortMessage: "Input value is not valid",
        message: "Input value is not valid",
      });
    }

    const [valueState, handlers, config] = useNumeric(
      value,
      onChange,
      formatStr,
    );

    const { align } = options || {};

    const inputStyle = useMemo(
      () => ({ textAlign: align || "right" }),
      [align],
    );

    const valueMemo = useMemo(
      () => (isNaN(valueState) ? "" : valueState),
      [valueState],
    );

    const { placeholder, ...configProps } = config;

    return (
      <NumberInput
        ref={ref}
        height="100%"
        width="100%"
        {...handlers}
        {...configProps}
        value={valueMemo}
        onFocus={(e) => {
          e.target.setSelectionRange(0, e.target.value.length);
        }}
      >
        <NumberInputField placeholder={placeholder} style={inputStyle} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    );
  },
);

InputNumericEditCell.displayName = "InputNumericEditCell";

InputNumericEditCell.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  props: PropTypes.any,
  options: PropTypes.shape({
    align: PropTypes.oneOf(["left", "center", "right"]),
  }),
  formatStr: PropTypes.any,
};

export default InputNumericEditCell;
