import { isObject } from "@chakra-ui/utils";

const getValue = (params, staticParams) => {
  const hasValueProperty =
    Object.keys(staticParams).filter((el) => el === "value").length > 0;

  if (hasValueProperty) {
    const value = staticParams.value;
    return value ? value : null;
  } else if (
    staticParams &&
    staticParams.variables &&
    Array.isArray(staticParams.variables)
  ) {
    let result = staticParams.variables.find(
      (variable) => variable.name === "value",
    )?.value;

    if (Array.isArray(result) && result.length > 0) {
      result = result[0];
      if (typeof result === "object" && isObject(result)) {
        result = Object.values(result)[0];
      } else if (typeof result === "object") {
        result = null;
      }
    }
    return result;
  } else if (params && Array.isArray(params) && params.length > 0) {
    return params[0];
  } else {
    return null;
  }
};

const getResultModel = (fixedPath, value, staticParams) => {
  const { spreadObject, __model } = staticParams;
  const propertyName = fixedPath.split(".")[1];

  if (!propertyName && spreadObject) {
    return { ...staticParams, __model: { ...__model, ...value } };
  }

  return { ...staticParams, __model: { ...__model, [propertyName]: value } };
};

export const getChangeStateBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) => {
      const { name, callerId, callerGuid } = systemParams;
      const context = staticParams.useSourceContext
        ? systemParams?.source?.context || componentContext
        : componentContext;
      if (!context?.state?.__mounted) {
        return staticParams;
      }

      const value = getValue(params, staticParams);

      let path =
        (typeof params[1]?.path === "string"
          ? params[1].path
          : staticParams.path) || (staticParams?.spreadObject ? "" : "<name>");

      if (!path.includes("formSource.") && !path.includes(".")) {
        path = `formSource${
          !path && staticParams?.spreadObject ? "" : "."
        }${path}`;
      }

      const fixedPath =
        path === undefined || path === null
          ? path
          : path.replace("<name>", name);

      let isEqualToOld = true;

      try {
        isEqualToOld = context.functions.getStateProp(fixedPath) === value;
      } catch {
        isEqualToOld = true;
      }

      if (!isEqualToOld) {
        context.functions.setStateProp(
          fixedPath,
          value,
          !!staticParams?.spreadObject,
          `CHANGE_STATE_BLOCK_[${name}:${callerId};${callerGuid}]`,
        );
      }

      const result = getResultModel(fixedPath, value, staticParams);

      return result;
    },
  };
};
