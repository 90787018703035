import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import { Input } from "@echo/ui";
import ComponentPropertiesList from "./component-properties-list";
import LabeledSelectFormControl from "../../labeled-select-form-control/labeled-select-form-control";
import { uuidv4 } from "@echo/tools";

const ComponentPropertiesDesigner = ({ schema, onSchemaChange }) => {
  const [editPropValue, setEditPropValue] = useState({});
  const [submitPerformed, setSubmitPerformed] = useState(false);

  const validate = () => editPropValue.propName && editPropValue.propType;

  const fixSchemaObject = (schema) => (Array.isArray(schema) ? schema : []);

  const handleOnChange = (name, value) => {
    setEditPropValue({ ...editPropValue, [name]: value });
  };

  const handleOnSchemaChange = (newSchema) => {
    if (onSchemaChange) {
      onSchemaChange(newSchema);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!validate()) {
      setSubmitPerformed(true);
      return;
    }
    if (editPropValue?.id) {
      const newSchema = [...fixSchemaObject(schema)];
      const editElemIndex = newSchema.findIndex(
        (elem) => elem.id === editPropValue.id,
      );
      newSchema[editElemIndex] = editPropValue;
      Promise.all([
        handleOnSchemaChange(newSchema),
        setEditPropValue({}),
        setSubmitPerformed(false),
      ]);
    } else {
      Promise.all([
        handleOnSchemaChange([
          ...fixSchemaObject(schema),
          { ...editPropValue, id: uuidv4() },
        ]),
        setEditPropValue({}),
        setSubmitPerformed(false),
      ]);
    }
  };

  const handleOnClear = () =>
    Promise.all([setEditPropValue({}), setSubmitPerformed(false)]);

  const handleOnDelete = () =>
    Promise.all([
      handleOnSchemaChange(
        fixSchemaObject(schema).filter((s) => s.id !== editPropValue.id),
      ),
      setEditPropValue({}),
    ]);

  const handleOnRowClick = (row) =>
    Promise.all([setEditPropValue(row), setSubmitPerformed(false)]);

  return (
    <Flex height="100%" minHeight="100%" flexFlow="row">
      <Flex height="100%" minHeight="100%" flex="1.618" padding="24px 0px">
        <ComponentPropertiesList value={schema} onRowClick={handleOnRowClick} />
      </Flex>
      <Divider orientation="vertical" />
      <Flex
        as="form"
        onSubmit={handleSubmit}
        height="100%"
        minHeight="100%"
        flex="1"
        flexFlow="column"
        alignContent="center"
        alignItems="center"
      >
        <Flex
          width="100%"
          flex="1.618"
          alignItems="center"
          justifyContent="space-evenly"
          flexFlow="column"
        >
          <Flex flexFlow="column" gap="10px">
            <Input
              value={editPropValue.propName || ""}
              onChange={(e) => handleOnChange("propName", e.target.value)}
              label="Property name"
              isInvalid={submitPerformed && !editPropValue.propName}
            />
            <LabeledSelectFormControl
              value={editPropValue.propType || ""}
              onChange={(e) => handleOnChange("propType", e.target.value)}
              label="Property type"
              rootProps={{
                isInvalid: submitPerformed && !editPropValue.propType,
              }}
            >
              <option value="any">Any</option>
              <option value="func">Function</option>
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="bool">Boolean</option>
              <option value="object">Object</option>
              <option value="array">Array</option>
            </LabeledSelectFormControl>
          </Flex>
          <Text fontSize="xs">
            Fill required fields to add or
            <br /> update component property.
          </Text>
        </Flex>
        <Divider />
        <Flex width="100%" flex="1" justifyContent="center">
          <Flex flexFlow="column" justifyContent="center">
            <Button type="submit">
              {editPropValue?.id ? "Edit property" : "Add property"}
            </Button>
            <Button onClick={handleOnClear} variant="ghost">
              Clear
            </Button>
            <Button
              disabled={!editPropValue?.id}
              variant="ghost"
              colorScheme="red"
              onClick={handleOnDelete}
            >
              Delete property
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

ComponentPropertiesDesigner.propTypes = {
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      propName: PropTypes.string.isRequired,
      propType: PropTypes.oneOf([
        "func",
        "string",
        "number",
        "object",
        "any",
        "array",
      ]).isRequired,
    }).isRequired,
  ),
  onSchemaChange: PropTypes.func,
};

export default ComponentPropertiesDesigner;
