import { Box, Link, Stack, useDisclosure } from "@chakra-ui/react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import DetailsModal from "./details-modal";

const EchoErrorToast = ({
  reasonTitle,
  location,
  shortMessage,
  message,
  defaultIsOpen,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen });

  const handleOnDetails = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen(e);
  };

  return (
    <>
      <DetailsModal
        isOpen={isOpen}
        onClose={onClose}
        reasonTitle={reasonTitle}
        location={location}
        message={message}
        shortMessage={shortMessage}
      />
      <Stack>
        <Stack>
          {reasonTitle && (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "240px",
              }}
            >
              <b>{reasonTitle}</b>
            </p>
          )}
          {location && (
            <p
              style={{
                fontSize: "12px",
                marginTop: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "240px",
              }}
            >
              {location}
            </p>
          )}
          {(shortMessage || message) && (
            <p
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "240px",
              }}
            >
              {message || shortMessage}
            </p>
          )}
        </Stack>
        <Box
          display="flex"
          flexFlow="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Link color="var(--chakra-colors-redCa)" onClick={handleOnDetails}>
            Details
          </Link>
        </Box>
      </Stack>
    </>
  );
};

EchoErrorToast.propTypes = {
  reasonTitle: PropTypes.string,
  location: PropTypes.string,
  shortMessage: PropTypes.string,
  message: PropTypes.string,
  defaultIsOpen: PropTypes.bool,
};

/**
 * Show detailed error
 * @param {Object} messageObject - Error description object.
 * @param {string} messageObject.reasonTitle - Reason of an error.
 * @param {string} messageObject.location - Error location
 * @param {string} messageObject.shortMessage - Short message visible in toast.
 * @param {string} messageObject.message - Message shown in details dialog.
 * @param {string} messageObject.defaultIsOpen - Immediately open details dialog.
 */
export const showErrorToast = (messageObject) => {
  const { reasonTitle, location, shortMessage } = messageObject;
  if (reasonTitle || location || shortMessage) {
    toast.error(
      (toastProps) => <EchoErrorToast {...toastProps} {...messageObject} />,
      { autoClose: false, draggable: false },
    );
  } else {
    window.console.error(messageObject);

    const errorObject = {
      reasonTitle: "Error. Contact with administrator.",
      message: `${messageObject}`,
    };

    toast.error(
      (toastProps) => <EchoErrorToast {...toastProps} {...errorObject} />,
      { autoClose: false },
    );
  }
};
