import { Box, Spinner } from "@chakra-ui/react";

export const LoadingScreen = () => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner />
      Loading...
    </Box>
  );
};
