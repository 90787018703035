import { format, isMatch } from "date-fns";
import { pl } from "date-fns/locale";
import { useEffect, useState } from "react";

const getDateFromPromise = async (promise, dateFormat) => {
  const res = await promise();
  return format(res ? new Date(res) : new Date(), dateFormat, { locale: pl });
};

const getDateStringFromObj = (dateObj, dateFormat) =>
  format(dateObj, dateFormat, { locale: pl });

const getDateStringFromTime = (timeValue, dateFormat) => {
  const date = new Date(timeValue);
  return getDateStringFromObj(date, dateFormat);
};

const getDateStringFromISO = (date, dateFormat) => {
  if (date.includes("T")) {
    const dateObj = new Date(date);
    return getDateStringFromObj(dateObj, dateFormat);
  }

  return date;
};

export const getFormattedDate = (date, dateTimeFormat) => {
  const { dateFormat } = extractDateTimeFormats(dateTimeFormat);
  const dateType = typeof date;
  if (date) {
    switch (dateType) {
      case "function":
        return getDateFromPromise(date, dateFormat || "dd-MM-yyyy");
      case "string":
        return getDateStringFromISO(date, dateFormat || "dd-MM-yyyy");
      case "object":
        return getDateStringFromObj(date, dateFormat || "dd-MM-yyyy");
      case "number":
        return getDateStringFromTime(date, dateFormat || "dd-MM-yyyy");
      default:
        return date;
    }
  }

  return "";
};

const findIndexes = (arr, el) => {
  let idxArr = [];
  arr.forEach((c, idx) => {
    if (c === el) idxArr.push(idx);
  });
  return idxArr;
};

const getValue = (date, format, char) => {
  if (!format || !date) return null;
  const dateArr = date.split("");
  const formatArr = format.split("");
  const idxArr = findIndexes(formatArr, char);
  return idxArr.map((idx) => dateArr.find((_, id) => id === idx)).join("");
};

const getDatePartsValues = (dateObj, dateTimeFormat) => {
  const { dateFormat, timeFormat } = extractDateTimeFormats(dateTimeFormat);
  const day = getValue(dateObj.date, dateFormat, "d") || "01";
  const month = getValue(dateObj.date, dateFormat, "M") || "01";
  const year = getValue(dateObj.date, dateFormat, "y") || "1970";
  const hours = getValue(dateObj.time, timeFormat, "H") || "00";
  const minutes = getValue(dateObj.time, timeFormat, "m") || "00";
  return {
    day,
    month,
    year,
    hours,
    minutes,
  };
};

export const getSqlDateString = (dateObj, dateTimeFormat) => {
  const { day, month, year, hours, minutes } = getDatePartsValues(
    dateObj,
    dateTimeFormat,
  );
  const sqlDateString = `${year}-${month}-${day}T${hours}:${minutes}:00`;
  return sqlDateString;
};

const extractDateTimeFormats = (dateTimeFormat) => {
  const parts = dateTimeFormat.split(" ");
  let dateFormat = null;
  let timeFormat = null;

  for (const part of parts) {
    if (part.includes("-") || part.includes("/") || part.includes(".")) {
      dateFormat = part;
      break;
    }
  }

  for (const part of parts) {
    if (part.includes(":")) {
      timeFormat = part;
      break;
    }
  }

  return { dateFormat, timeFormat };
};

const getInitialDate = (initialDate, dateTimeFormat) => {
  const { dateFormat, timeFormat } = extractDateTimeFormats(
    dateTimeFormat || "dd-MM-yyyy",
  );

  let result = { date: "", time: "" };

  try {
    const dateMatch = isMatch(
      initialDate.toString(),
      dateTimeFormat || "dd-MM-yyyy",
      { locale: pl },
    );

    if (dateMatch) {
      result = { ...result, date: initialDate.toString().split(" ")[0] };
      if (initialDate.toString().split(" ")[1]) {
        result = { ...result, time: initialDate.toString().split(" ")[1] };
      }
    } else {
      const date = new Date(initialDate.toString());
      const formattedDate = format(date, dateFormat || "dd-MM-yyyy", {
        locale: pl,
      });

      result = { ...result, date: formattedDate };

      if (timeFormat) {
        const formattedTime = format(date, timeFormat || "HH:mm", {
          locale: pl,
        });
        result = { ...result, time: formattedTime };
      }
    }
  } catch {
    result = { date: "", time: "" };
  }

  return result;
};

/**
 * @param {string|Date|number} initialDate initial date value
 * @param {string} dateFormat format in which the date should be returned
 * @param {function} callback function that will be called after the date is changed
 * @returns {Array} state variable and setter for this variable
 */
// iso date type 2024-02-16T00:00:00
export const useDate = (initialDate, dateTimeFormat) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!initialDate || initialDate === "") {
      setDate("");
      setTime("");
    }

    if (initialDate) {
      const { date, time } = getInitialDate(initialDate, dateTimeFormat);
      setDate(date);
      setTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate]);

  return {
    date,
    time,
    setDate,
    setTime,
  };
};
