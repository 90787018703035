import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";

const DataGridContent = ({ children }) => {
  return (
    <Box
      as="tbody"
      display="block"
      width="100%"
      overflowY="auto"
      height="auto"
      maxHeight="100%"
    >
      {children}
    </Box>
  );
};

DataGridContent.propTypes = {
  children: PropTypes.node,
};

export default DataGridContent;
