import PropTypes from "prop-types";
import PropertiesTable from "../properties-table";
import { testStyleProps } from "../../../../../utils/css/base-echo-style-props";
import { useEffect, useState } from "react";

const camelToKebab = (camelCaseString) =>
  camelCaseString?.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

export const StylePropertiesTable = ({ model, onModelChange }) => {
  const [cssProperties, setCssProperties] = useState();

  const getCssProperties = async () => {
    const gistUrl =
      "https://api.github.com/gists/9e890e2fef9d7f5b819e0550c26566b4";

    try {
      const result = await fetch(gistUrl);
      const data = await result.json();
      const properties = JSON.parse(data.files["css-properties.json"].content);
      properties.gap = { values: ["[length]"] };
      properties.width = {
        ...properties.width,
        values: [...properties.width.values, "fit-content"],
      };
      properties.height = {
        ...properties.height,
        values: [...properties.height.values, "fit-content"],
      };
      setCssProperties(properties);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCssProperties();
  }, []);

  const getOptionType = (type) => {
    switch (type) {
      case "combo":
        return "combo";
      default:
      case "number":
      case "text":
        return "input";
    }
  };

  const getComboOptions = (option) => {
    const splitOptions = option.valuePattern.split("|");
    const splitNames = option.displayName.split("|");
    if (splitOptions.length !== splitNames.length) {
      throw new Error("Invalid valuePattern or displayName.");
    }
    return splitOptions.map((s, index) => ({
      value: s,
      label: splitNames[index],
    }));
  };

  const getSubOptions = (availableValues, propName) => {
    if (availableValues.length > 1) {
      if (model && model["opt-" + propName]) {
        const value = availableValues.find(
          (av) => av.displayName === model["opt-" + propName],
        );
        return value
          ? [
              {
                propName,
                propType: getOptionType(value.valueType),
                options:
                  value.valueType === "combo"
                    ? getComboOptions(value)
                    : undefined,
              },
            ]
          : undefined;
      }
    }
    return undefined;
  };

  const getTooltip = (prop) => {
    const kebabProp = camelToKebab(prop);
    const obj = cssProperties?.[kebabProp];
    let tooltip = obj?.values.join(", ");

    if (obj?.syntax?.includes("{")) {
      tooltip = tooltip.replace(
        "[length], [percent]",
        "[px px px px], [% % % %]",
      );
    }

    return tooltip;
  };

  const schema = testStyleProps.map((s) => ({
    propName:
      s?.availableValues?.length > 1
        ? "opt-" + s.styleProperty
        : s.styleProperty,
    propType:
      s?.availableValues?.length > 1
        ? "combo"
        : getOptionType(s.availableValues[0].valueType),
    options:
      s?.availableValues?.length > 1
        ? s.availableValues.map((av) => ({
            label: av.displayName,
            value: av.styleProperty,
          }))
        : s.availableValues[0].valueType === "combo"
          ? getComboOptions(s.availableValues[0])
          : undefined,
    subOptions: getSubOptions(s.availableValues, s.styleProperty),
    tooltip: getTooltip(s.styleProperty),
  }));

  return (
    <PropertiesTable
      options={schema}
      model={model}
      onModelChange={onModelChange}
    />
  );
};

StylePropertiesTable.propTypes = {
  model: PropTypes.object,
  onModelChange: PropTypes.func,
};
