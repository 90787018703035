import { useCallback, useEffect, useState } from "react";
import { getLatLng, getZoomByAddressType } from "../utils/utils";
import { useGeocoding } from "./use-geocoding";

export const useGoogleMap = (value, defaultCenter) => {
  const [selected, setSelected] = useState();
  const [mapProps, setMapProps] = useState({});

  const geoLib = useGeocoding();

  const setMapCenter = useCallback(
    (center) => setMapProps((prev) => ({ ...prev, center })),
    [setMapProps],
  );

  const setMapZoom = useCallback(
    (zoom) => setMapProps((prev) => ({ ...prev, zoom })),
    [setMapProps],
  );

  const onCameraChange = (ev) =>
    setMapProps({ center: ev.detail.center, zoom: ev.detail.zoom });

  const onCenterChange = useCallback(
    ({ position, type }) => {
      setMapCenter(position);
      const zoom = getZoomByAddressType(type);
      setMapZoom(zoom);
    },
    [setMapCenter, setMapZoom],
  );

  const onSelectionChange = useCallback(
    (selection) => {
      setSelected(selection);
      onCenterChange(selection);
    },
    [onCenterChange, setSelected],
  );

  const initCenter = useCallback(
    async (center) => {
      const res = await geoLib.getLocationByAddress(center);
      onCenterChange(res);
    },
    [geoLib, onCenterChange],
  );

  const initLocation = useCallback(
    async (value) => {
      const location = getLatLng(value);
      const type = location ? "latLng" : "address";

      if (type === "latLng") {
        const res = await geoLib.getLocationByLatLng(location);
        onSelectionChange(res);
        return;
      }

      const res = await geoLib.getLocationByAddress(value);
      onSelectionChange(res);
    },
    [geoLib, onSelectionChange],
  );

  const initMap = useCallback(
    async (center, value) => {
      if (!geoLib) return;

      if (!value && center) {
        await initCenter(center);
        return;
      }

      await initLocation(value);
    },
    [geoLib, initCenter, initLocation],
  );

  useEffect(() => {
    if (value) {
      initLocation(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!mapProps.center) {
      initMap(defaultCenter, value);
    }
  }, [defaultCenter, initMap, mapProps.center, value]);

  return {
    mapProps,
    selected,
    onCameraChange,
    onCenterChange,
    onSelectionChange,
  };
};
