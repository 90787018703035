import { useContext } from "react";
import UserContext from "../../../../../../../user-context";
import { mobileCheck } from "@echo/ui";
import { catchEventError } from "../../datagrid-component/utils/catch-event-error";

export const useTableActions = (api) => {
  const {
    onColumnChange,
    onFilterChange,
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onRowEdit,
    onRowAdd,
    onRowClick,
    onRowDoubleClick,
    unselectAll,
    selectSingle,
    selectMany,
  } = api;

  const isMobile = mobileCheck();
  const { userId, contextId, companyId, permissions } = useContext(UserContext);
  const roleId = (permissions.length && permissions[0]?.role?.id) || null;

  const handleRowClick = (e) => {
    if (!onRowClick) {
      if (isMobile) onRowDoubleClick(e).catch(catchEventError);
    } else {
      onRowClick(e);
    }
  };

  const handleColumnChange = (columns) =>
    onColumnChange(columns, userId, null, "user", companyId);

  const resetAction = async () =>
    await onColumnChange([], contextId || userId, roleId, null);

  const tableActions = {
    onRowClick: handleRowClick,
    onRowDoubleClick,
    onFilterChange: (val) => {
      onFilterChange(val);
      unselectAll();
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      unselectAll();
    },
    onPageChange,
    onSortChange: (val) => {
      onSortChange(val);
      unselectAll();
    },
    onColumnWidthChange: handleColumnChange,
    onColumnOrderChange: handleColumnChange,
    onColumnVisibilityChange: handleColumnChange,
    onColumnsSettingsChange: onColumnChange,
    onRowSelect: selectSingle,
    onSelectAll: selectMany,
    onRowEdit,
    onRowAdd,
    resetAction,
  };

  return tableActions;
};
