import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useDayzed } from "dayzed";
import {
  Month_Names_Short_PL,
  Weekday_Names_Short_PL,
} from "./utils/calendar-utils";
import { CalendarPanel } from "./components/calendar-panel";
import PropTypes from "prop-types";
import { Input, mobileCheck } from "@echo/ui";
import {
  getSqlDateString,
  useDate,
} from "../echo-components/base-components/components/datagrid-component/hooks/use-formatted-date";
import { isMatch } from "date-fns";
import { toast } from "react-toastify";
import { useDatepickerHandlers } from "./hooks/use-datepicker-handlers";
import { FaRegCalendarAlt } from "react-icons/fa";
import { formatDateValue } from "./utils/format-date-value";

// utils
const DefaultConfigs = {
  dateFormat: "dd-MM-yyyy",
  monthNames: Month_Names_Short_PL,
  dayNames: Weekday_Names_Short_PL,
};

export const SingleDatepicker = forwardRef(
  (
    {
      style,
      label,
      dateTimeFormat = "dd-MM-yyyy",
      configs = DefaultConfigs,
      propsConfigs,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      nodeRef,
      onClick,
      onMouseOver,
      onMouseLeave,
      isReadOnly,
      isInvalid,
      isRequired,
      tabIndex,
      autoFocus,
      ...props
    },
    ref,
  ) => {
    const { date, name, disabled, onDateChange, onBlur, id } = props;
    const { isOpen, onToggle, onClose } = useDisclosure();

    const dateFormat = useMemo(() => {
      const arr = dateTimeFormat.split("");
      if (!dateTimeFormat.toLowerCase().includes("h")) return dateTimeFormat;

      const index = arr.findIndex((c) => c.toLowerCase() === "h");
      const withoutTime = arr.splice(0, index - 1).join("");
      return withoutTime;
    }, [dateTimeFormat]);

    const {
      date: dateValue,
      time: timeValue,
      setDate,
      setTime,
    } = useDate(date, dateTimeFormat);

    const isMobile = mobileCheck();
    const isDateTime = dateTimeFormat
      ? dateTimeFormat.toLowerCase().includes("h")
      : false;

    // const [popoverOpen, setPopoverOpen] = useState(false);
    const [error, setError] = useState(false);

    const {
      onKeyDown,
      onChange,
      onSelect,
      onClick: onInputClick,
      onDateSelect,
      onPaste,
    } = useDatepickerHandlers(dateFormat, setDate);

    const validate = (date, format) => {
      const isCorrect = isMatch(date, format);

      if (!date || date === "" || isCorrect) {
        setError(false);
        return true;
      }

      setError(true);
      return false;
    };

    const handleKeyDown = (e) => onKeyDown(e);
    const handleDateChange = (e) => onChange(e);
    const handleSelect = (e) => onSelect(e);

    const handleOnClick = (e) => {
      if (isReadOnly) return;
      onInputClick(e);

      if (onClick) {
        onClick(e);
      }
    };

    const handlePaste = (e) => onPaste(e);

    const handleOnDateSelected = ({ selectable, date }) => {
      setError(false);
      (ref ?? inputRef).current.focus();
      const isSelected = onDateSelect({ selectable, date });
      if (isSelected) onClose();
    };

    const handleBlur = useCallback(
      (e) => {
        const formattedDate = formatDateValue(e, dateValue, dateFormat);
        const isCorrect = validate(formattedDate, dateFormat);

        if (!isCorrect) {
          setDate(formattedDate);
          return;
        }

        try {
          if (formattedDate === "" || !formattedDate) {
            onDateChange(formattedDate);
            if (onBlur) onBlur(formattedDate);
            return;
          }
          const sqlDateString = getSqlDateString(
            { date: formattedDate, time: timeValue },
            dateTimeFormat,
          );
          onDateChange(sqlDateString);
          if (onBlur) onBlur(sqlDateString);
        } catch (e) {
          console.error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [dateValue, timeValue, dateFormat, dateTimeFormat, onDateChange, onBlur],
    );

    // chakra popover utils
    const popoverRef = useRef(null);
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    useOutsideClick({
      ref: containerRef,
      handler: onClose,
    });

    // dayzed utils

    const dayzedData = useDayzed({
      showOutsideDays: true,
      onDateSelected: handleOnDateSelected,
      selected: date,
    });

    const handleTimeChange = useCallback(
      (e) => {
        const time = e.target.value;
        const sqlDateString = getSqlDateString(
          { date: dateValue, time },
          dateTimeFormat,
        );
        setTime(time);
        onDateChange(sqlDateString);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dateValue, timeValue, dateTimeFormat],
    );

    const styleModifier = {
      name: "zIndex",
      enabled: true,
      phase: "main",
      fn({ state }) {
        state.styles.popper["z-index"] = 9999999;
      },
    };

    const dateInputStyles = {
      ...style,
      marginRight: isDateTime ? "0" : "",
    };

    const timeInputStyles = {
      ...style,
      marginLeft: isDateTime ? "0" : "",
    };

    useEffect(() => {
      if (error)
        toast.error(`${label} - provided date is incorrect.`, {
          autoClose: 3000,
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
      <Box
        style={{ order: style?.order }}
        display="flex"
        alignItems="center"
        gap="0"
        height={style?.height}
        data-testid="datepicker-trigger-test"
        onBlur={handleBlur}
        ref={containerRef}
      >
        <Popover
          placement="bottom"
          isOpen={isOpen}
          onClose={onClose}
          initialFocusRef={popoverRef}
          modifiers={[styleModifier]}
          isLazy
        >
          <PopoverTrigger>
            <Input
              ref={ref ?? inputRef}
              id={id}
              onSelect={handleSelect}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onTouchEnd={onTouchEnd}
              onTouchStart={onTouchStart}
              onFocus={() => setError(false)}
              style={dateInputStyles}
              nodeRef={nodeRef}
              onMouseOver={onMouseOver}
              onPaste={handlePaste}
              onMouseLeave={onMouseLeave}
              isDisabled={disabled}
              label={label}
              onClick={handleOnClick}
              onDoubleClick={(e) => {
                if (!isMobile) e.target.setSelectionRange(0, dateValue.length);
              }}
              isReadOnly={isReadOnly || isMobile}
              name={name}
              value={dateValue}
              onChange={handleDateChange}
              isInvalid={error || isInvalid}
              isRequired={isRequired}
              {...propsConfigs?.inputProps}
              data-testid="datepicker-input-test"
              icon={
                <IconButton
                  size="md"
                  tabIndex={tabIndex}
                  _focus={{ outline: "blue" }}
                  icon={<FaRegCalendarAlt />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onToggle();
                  }}
                  variant="ghost"
                />
              }
              tabIndex={tabIndex}
              autoFocus={autoFocus}
            />
          </PopoverTrigger>
          <PopoverContent
            ref={popoverRef}
            width="100%"
            data-testid="datepicker-content-test"
          >
            <PopoverBody>
              <CalendarPanel
                renderProps={dayzedData}
                configs={configs}
                propsConfigs={propsConfigs}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        {isDateTime && (
          <Input
            onClick={(e) => e.stopPropagation()}
            isDisabled={disabled}
            isInvalid={isInvalid}
            isRequired={isRequired}
            onChange={handleTimeChange}
            value={timeValue || "00:00"}
            style={{ ...timeInputStyles, width: "fit-content" }}
            type="time"
          />
        )}
      </Box>
    );
  },
);

SingleDatepicker.displayName = "SingleDatepicker";

SingleDatepicker.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onFormCreateAction: PropTypes.func,
  onFormReadAction: PropTypes.func,
  onCancelHover: PropTypes.func,
  style: PropTypes.object,
  nodeRef: PropTypes.object,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  date: PropTypes.any,
  dateTimeFormat: PropTypes.string,
  configs: PropTypes.shape({
    dateFormat: PropTypes.string,
    monthNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  propsConfigs: PropTypes.shape({
    dateNavBtnProps: PropTypes.any, //ButtonProps,
    dayOfMonthBtnProps: PropTypes.shape({
      electedBg: PropTypes.any, //BackgroundProps['bg'],
      disabledBg: PropTypes.any, //BackgroundProps['bg'],
    }),
    inputProps: PropTypes.any, //InputProps,
  }),
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
};
