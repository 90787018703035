import PropTypes from "prop-types";
import { shimFunctions } from "../../../../utils/shims/shims";

const ComponentShim = () => {
  return <div />;
};

ComponentShim.propTypes = {
  nodeRef: PropTypes.any,
  onClick: PropTypes.funcOf(PropTypes.object),
  onMouseLeave: PropTypes.funcOf(PropTypes.object),
  onMouseOver: PropTypes.funcOf(PropTypes.object),
};

export default ComponentShim;
