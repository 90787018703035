import { GridItem } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import * as FeatherIcons from "react-icons/fi";
import { tileIconSet } from "./tile-icons";

const Tile = ({
  onClick,
  path,
  icon,
  title = "Untitled",
  color = "white",
  backgroundColor = "#EE2E24",
  hoverColor = "white",
  hoverBackgroundColor = "#EE2E24ED",
  rowSpan = 1,
  colSpan = 1,
}) => {
  const handleOnClick = useCallback(
    (e) => {
      if (typeof onClick === "function") {
        onClick(e, path, title);
      }
    },
    [onClick, path, title],
  );

  const IconComponent =
    FeatherIcons[tileIconSet.includes(icon) ? icon : "FiAlertTriangle"];

  return (
    <GridItem
      display="flex"
      flexFlow="column"
      cursor={path ? "pointer" : undefined}
      justifyContent="center"
      _hover={
        path
          ? { backgroundColor: hoverBackgroundColor, color: hoverColor }
          : undefined
      }
      alignContent="center"
      alignItems="center"
      color={color}
      backgroundColor={backgroundColor || "#EE2E24ED"}
      rowSpan={rowSpan}
      colSpan={colSpan}
      onClick={handleOnClick}
    >
      <IconComponent
        className="disable-ppz-pan"
        size="35px"
        onMouseMove={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {title}
    </GridItem>
  );
};

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  rowSpan: PropTypes.number,
  colSpan: PropTypes.number,
  onClick: PropTypes.func,
  path: PropTypes.func,
};

export default Tile;
