import React from "react";
import * as FeatherIcons from "react-icons/fi";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { tileIconSet } from "./tile-icons";

const getShapeStyleByType = (type) => {
  switch (type) {
    case 2:
    case "circle":
      return { height: "80px", width: "80px", borderRadius: "50%" };
    case 3:
    case "diamond":
      return { width: "56px", height: "56px", transform: "rotate(45deg)" };
    default:
    case 1:
    case "square":
      return { height: "80px", width: "80px" };
  }
};

const Tile = ({
  icon = "FiAlertCircle",
  type = "square",
  color = "orange",
}) => {
  const IconComponent = FeatherIcons[icon];

  return (
    <Box
      className="disable-ppz-pan"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="white"
      style={getShapeStyleByType(type)}
      backgroundColor={color}
    >
      <IconComponent
        className="disable-ppz-pan"
        size="35px"
        onMouseMove={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Box>
  );
};

Tile.propTypes = {
  type: PropTypes.oneOf([1, 2, 3, "circle", "square", "diamond"]),
  color: PropTypes.string,
  icon: PropTypes.oneOf(tileIconSet),
};

export default Tile;
