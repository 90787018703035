import { showErrorToast } from "../components/shared/echo-error-toast";
import { ERROR_SEVERITY, createLogger } from "../logger/logger";

export const registerErrorHandlers = () => {
  const logger = createLogger(ERROR_SEVERITY.ERROR);

  window.onerror = (message, source, lineno, colno, error) => {
    // Send errorData to a logging service (e.g., via an HTTP request)
    logger
      .logError(JSON.stringify({ colno, lineno }), message, {
        userAgent: window.navigator.userAgent,
        error: error ? error.stack : null,
      })
      .then((correlationId) => {
        showErrorToast({
          reasonTitle: "Unexpected error",
          location: "Hidden",
          shortMessage: `Unexpected error. Correlation id: ${correlationId}`,
          message: `Unexpected error. Correlation id: ${correlationId}`,
        });
      });

    console.error(error);

    // Return true to prevent the default browser error handling
    return true;
  };

  window.onunhandledrejection = (event) => {
    logger.logError(
      "JavaScript Promise",
      `Unhandled rejection: ${event.reason}`,
      {
        userAgent: window.navigator.userAgent,
      },
    );

    event.preventDefault();
  };
};
