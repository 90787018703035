// @flow

import React, { useEffect, useState } from "react";
import { Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Cell } from "../cell/cell";
import {
  DATAGRID_FILTER_ROW_CELL_TESTID,
  DATAGRID_FILTER_ROW_TESTID,
} from "../../utils/test-ids";
import { FilterCell } from "../cell/filter-cell";
import { addFilter, changeFilter } from "../../utils/filter-utils";
import { useScrollContext } from "../../hooks/use-scroll-context";

export const FilterRow = ({ columns }) => {
  const {
    filters,
    actions: { onFilterChange },
    selectionMode,
  } = useDatagridContext();

  const [filtersState, setFiltersState] = useState([]);

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  const handleFilterValue = (name, value, idx) =>
    setFiltersState((prev) => {
      const f = prev.find((el) => el.name === name);

      if (!f) return addFilter(prev, name, value);

      return changeFilter(prev, name, value, idx);
    });

  const getNewValues = (values) => {
    if (!values[0] && values[1]) return [values[1]];
    return [values[0]];
  };

  const handleFilterType = (name, type) =>
    setFiltersState((prev) => {
      const f = prev.find((el) => el.name === name);
      if (f) {
        return prev.map((i) => {
          if (i.name === name) {
            if (type !== "between") {
              const newValues = getNewValues(i.values);
              return { ...i, values: newValues, type };
            }
            return { ...i, type };
          }
          return i;
        });
      }

      return [...prev, { name, values: [], type }];
    });

  useEffect(() => {
    let timeout;

    if (filtersState) {
      timeout = setTimeout(() => {
        onFilterChange(filtersState);
      }, 500);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState]);

  return (
    <Tr
      width="100%"
      display="block"
      borderBottom="1px solid black"
      data-testid={DATAGRID_FILTER_ROW_TESTID}
    >
      {columns.map((col) =>
        col.name === "checkbox" ? (
          selectionMode !== "none" && (
            <Cell
              column={col}
              width={col.width}
              key={`empty-${col.name}`}
              data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
            />
          )
        ) : (
          <FilterCell
            filter={filtersState.find((el) => el.name === col.name)}
            onFilterValueChange={handleFilterValue}
            onFilterTypeChange={handleFilterType}
            column={col}
            key={col.name}
            data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
          />
        ),
      )}
    </Tr>
  );
};
