export const getDatagridActionsPropType = (shape) => {
  const validatorFunc = (props, propName, componentName) => {
    if (props && props[propName] && !Array.isArray(props[propName])) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to" +
          " `" +
          componentName +
          "`. Validation failed. Property of type array should be passed instead of " +
          typeof props[propName],
      );
    } else {
      return undefined;
    }
  };

  validatorFunc.info = {
    propTypeName: "datagridActionsArrayOf",
    allowedChildrenPropType: {
      info: shape.info,
    },
  };

  return validatorFunc;
};
