const getLocaleDecimalSeparator = (locale) => {
  const numberWithDecimalSeparator = 1.1;

  return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
};

const localeDecimalSeparator = getLocaleDecimalSeparator(navigator.language);

const hasFractionDigits = (value, separator) => {
  const arr = value.split(separator);
  return arr[1] ? true : false;
};

const getRoundedFraction = (value, separator, digits) => {
  const valueWithDot = value.replace(separator, ".");
  const numberValue = parseFloat(valueWithDot);
  const roundedValue = numberValue.toFixed(digits);
  return roundedValue.replace(".", separator);
};

const getDefaultFraction = (digits) => {
  let result = "";
  for (let i = 0; i < digits; i++) {
    result += "0";
  }
  return result;
};

export const getValueWithFraction = (value, separator, digits) => {
  if (!separator) return value;
  const hasFraction = hasFractionDigits(value, separator);

  if (!hasFraction) {
    const defaultFraction = getDefaultFraction(digits);
    return value + separator + defaultFraction;
  }

  const valueWithFraction = getRoundedFraction(value, separator, digits);
  return valueWithFraction;
};

export const splitValue = (value, separator) => {
  if (!separator || (separator && !value.includes(separator)))
    return { integers: value };

  const splitted = value.split(separator);
  const integers = splitted[0];
  const fraction = splitted[1];

  return { integers, fraction };
};

export const splitBySize = (string, size, separator) => {
  const reversed = string.split("");
  reversed.reverse();
  let result = [];

  for (let i = 0; i < reversed.length; i++) {
    if (i > 0 && i % size === 0) {
      result.unshift(separator);
    }

    result.unshift(reversed[i]);
  }

  return result.join("");
};

const getResultValue = (valueArr, formatArr, loopLength, separator) => {
  let result = [];
  for (let i = 0; i < loopLength; i++) {
    const current = valueArr[i];
    const next = valueArr[i + 1];
    const isFormatPlace = formatArr[i] === "0" || formatArr[i] === "#";

    if (separator && current === separator && !isFormatPlace) {
      result.unshift(current);
    }

    if (formatArr[i] === "0") {
      if (current) result.unshift(current);
      if (!current) result.unshift("0");
    }

    if (formatArr[i] === "#") {
      if ((current == "0" && next) || parseInt(current) > 0) {
        result.unshift(current);
      }
    }

    if (!formatArr[i]) {
      if (formatArr[formatArr.length - 1] === "#") {
        if ((current == "0" && next) || parseInt(current) > 0) {
          result.unshift(current);
        }
      }
    }
  }

  return result.join("");
};

const toReversedArray = (toReverse) => {
  let result = [...toReverse];
  result.reverse();
  return result;
};

const getValueWithoutSeparator = (value, format) => {
  const reversed = toReversedArray(value.split(""));
  const reversedFormat = toReversedArray(format.split(""));

  const loopLength =
    reversed.length > reversedFormat.length
      ? reversed.length
      : reversedFormat.length;

  const result = getResultValue(reversed, reversedFormat, loopLength);
  return result;
};

const getValueWithSeparator = (value, format, separator) => {
  const groupSize = getGroupSize(format, separator);
  const splitted = splitBySize(value, groupSize, separator);

  const reversed = toReversedArray(splitted.split(""));
  const reversedFormat = toReversedArray(format.split(""));

  const loopLength =
    reversed.length > reversedFormat.length
      ? reversed.length
      : reversedFormat.length;

  const result = getResultValue(
    reversed,
    reversedFormat,
    loopLength,
    separator,
  );
  return result;
};

export const formatIntegers = (value, format, separator) => {
  if (!separator) {
    const result = getValueWithoutSeparator(value, format);
    return result;
  }

  const result = getValueWithSeparator(value, format, separator);
  return result;
};

const digitsFormatCharacters = ["#", "0"];

const digitsMinusRegex = /^[0-9-]+$/;
const zeroHashRegex = /^[#0]+$/;

export const showAlways = (c) => c === "0";
export const showGreaterThanZero = (c) => c === "#";

export const isDigitOrMinus = (str) => digitsMinusRegex.test(str);

export const testZeroHash = (str) => zeroHashRegex.test(str);

export const getSeparators = (format) => {
  let decimal;
  let group;

  const chars = format.split("");
  const decimalSeparator = chars.find((c) => c === ".");
  const groupSeparator = chars.find((c) => !testZeroHash(c) && c !== ".");

  if (decimalSeparator) decimal = decimalSeparator;
  if (groupSeparator) group = groupSeparator;

  return { decimal, group };
};

export const getPartFormats = (format, decimalSeparator) => {
  const arr = format.split(decimalSeparator);
  const intFormat = arr[0] ? arr[0] : arr[0];
  const fractionFormat = arr[1] ? arr[1] : undefined;

  return { intFormat, fractionFormat };
};

export const getGroupSize = (format, separator) => {
  const arr = format.split(separator);
  let result = 0;
  if (arr.length > 1) {
    arr.forEach((p) => {
      if (p.length > result) result = p.length;
    });
  }

  return result;
};

export const splitFormat = (format) => {
  const { decimal, group } = getSeparators(format);
  const { intFormat, fractionFormat } = getPartFormats(format, decimal);
  const decimalPlaces = fractionFormat ? fractionFormat.length : 0;

  return {
    intFormat,
    fractionFormat,
    decimalPlaces,
    decimalSeparator: decimal,
    groupSeparator: group,
  };
};

const getDefaultValue = (separator, fraction) => {
  let result = "0";

  if (separator) result += localeDecimalSeparator;
  if (fraction > 0) {
    for (let i = 0; i < fraction; i++) {
      result += "0";
    }
  }

  return result;
};

const getNumericValue = (value, groupSeparator, decimalSeparator) => {
  let result = value;

  if (groupSeparator && result.includes(groupSeparator)) {
    result = result.replaceAll(groupSeparator, "");
  }

  if (decimalSeparator) {
    if (!groupSeparator || (groupSeparator && groupSeparator !== ","))
      result = result.replace(",", ".");
  }

  return parseFloat(result);
};

export const formatValue = (value, format) => {
  const { intFormat, groupSeparator, decimalSeparator, decimalPlaces } =
    splitFormat(format);

  if (!value) return getDefaultValue(decimalSeparator, decimalPlaces);

  const stringValue = value.toString();
  const isNegative = stringValue.startsWith("-");

  const numericValue = getNumericValue(
    stringValue,
    groupSeparator,
    decimalSeparator,
  );

  const roundedValue = numericValue.toString().replace(".", decimalSeparator);

  const valueWithFraction = getValueWithFraction(
    roundedValue,
    decimalSeparator,
    decimalPlaces,
  );

  const { integers, fraction } = splitValue(
    valueWithFraction,
    decimalSeparator,
  );

  const formattedInts = formatIntegers(integers, intFormat, groupSeparator);

  const result =
    (isNegative ? "-" : "") +
    (formattedInts ? formattedInts : "") +
    (decimalSeparator ? localeDecimalSeparator : "") +
    (fraction ? fraction : "");

  return result;
};
