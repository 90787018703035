//  process listenValueChange

import { getBlock } from "../../services/diagram-service";
import { getBlockExecutor } from "../get-block";

const getFormSourcePath = (staticParams, propName) => {
  let path = propName || (staticParams?.spreadObject ? "" : "<name>");

  if (!path.includes("formSource.") && !path.includes(".")) {
    path = `formSource${!path && staticParams?.spreadObject ? "" : "."}${path}`;

    return path;
  }

  const fixedPath =
    path === undefined || path === null
      ? path
      : path.replace("<name>", propName);

  return fixedPath;
};

const executeProcess = async (ctx, systemParams, staticParams, params) => {
  let result;
  const {
    state: { formSource },
    functions: { setStateProp },
  } = ctx;
  const { returnDataType, callbackEvent } = staticParams;

  try {
    const block = await getBlock(callbackEvent.id);
    const executor = getBlockExecutor(block, ctx);
    const res = await executor.execute(staticParams, params);
    result = res;

    if (systemParams && returnDataType === "value") {
      const { name, callerId, callerGuid } = systemParams;
      const key = Object.keys(result[0])[0];

      if (result[0] && key) {
        const value = result[0][key];

        if (value !== formSource[name]) {
          const fixedPath = getFormSourcePath(staticParams, name);
          setStateProp(
            fixedPath,
            value,
            !!staticParams?.spreadObject,
            `CHANGE_STATE_BLOCK_[${name}:${callerId};${callerGuid}]`,
          );
        }
      }
    }
  } catch (e) {
    result = returnDataType === "value" ? "" : [];
    console.error(e);
  }

  return result;
};

export const readValueBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams, params, systemParams) => {
      const {
        state: { __mounted, formSource },
      } = componentContext;
      const { returnDataType, path } = staticParams;

      let result = returnDataType === "value" ? "" : [];
      if (__mounted && formSource && formSource[path]) {
        try {
          result = await executeProcess(
            componentContext,
            systemParams,
            {
              ...staticParams,
              sessionData: {
                userId: parseFloat(
                  componentContext.userContext.contextId ||
                    componentContext.userContext.userId,
                ),
                userName: componentContext.userContext.userName,
                origin: window.origin,
                companyId: isNaN(componentContext.userContext.companyId)
                  ? componentContext.userContext.companyId
                  : Number(componentContext.userContext.companyId),
              },
            },
            params,
          );
        } catch (e) {
          result = returnDataType === "value" ? "" : [];
          console.error(e);
        }
      }

      switch (returnDataType) {
        case "dataSource":
          return result;
        case "value":
        default:
          if (typeof result[0] === "object") {
            const key = Object.keys(result[0])[0];

            return result[0][key];
          }

          return null;
      }
    },
  };
};
