import { withBaseComponent } from "../../../with-base-component";
import { Box, useDisclosure } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CalendarHeader } from "./calendar-header/calendar-header";
import { CalendarBody } from "./calendar-body/calendar-body";
import { isSameWeek } from "date-fns";
import {
  getAvailableCalendars,
  getEvents,
  mapEvents,
  saveCalendarSetting,
} from "./utils/calendar-utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { useCalendar } from "./hooks/use-calendar";
import { CalendarNavigation } from "./calendar-nav/calendar-navigation";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { showErrorToast } from "../../../../echo-error-toast";
import { CalendarDragContextProvider } from "./context/calendar-drag-context";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { ContextMenu } from "@echo/ui";
import { CalendarSettingsModal } from "./calendar-settings-modal/calendar-settings-modal";
import UserContext from "../../../../../../user-context";

const Calendar = (props) => {
  const {
    id,
    guid,
    nodeId,
    dataSource,
    context,
    style,
    nodeRef,
    rootProps,
    onEventAdd,
    onEventEdit,
    onEventMove,
    designerMode,
    ...restProps
  } = props;
  const {
    openContextMenu,
    closeContextMenu,
    isContextMenuOpened,
    clickPosition,
  } = useContextMenu();
  const { userId } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { weekStart, weekEnd, weekDays, nextWeek, prevWeek } = useCalendar();
  const [, component] = useApiContext(id, guid, nodeId);
  const [events, setEvents] = useState([]);
  const [availableCalendars, setAvailableCalendars] = useState([]);

  const getCalendarEvents = async (dataSource, context, component) => {
    try {
      const events = await getEvents(dataSource, context, component);
      const mappedEvents = mapEvents(events);
      setEvents(mappedEvents);
    } catch (e) {
      showErrorToast(e);
    }
  };

  useEffect(() => {
    if (!designerMode) getCalendarEvents(dataSource, context, component);
  }, [dataSource, context, component, designerMode]);

  const data = useMemo(
    () =>
      events.length > 0 && availableCalendars.length > 0
        ? events
            .filter(
              (ev) =>
                availableCalendars.find((c) => ev.calendarId === c.id).isVisible
                  .value,
            )
            .filter((ev) => isSameWeek(ev.from, weekStart, { weekStartsOn: 1 }))
        : [],
    [weekStart, events, availableCalendars],
  );

  const contextMenuActions = [{ label: "Calendar settings", action: onOpen }];

  const getCalendars = async (events, userId) => {
    const calendars = await getAvailableCalendars(events, userId);
    setAvailableCalendars(calendars);
  };

  useEffect(() => {
    getCalendars(events, userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events.length]);

  const handleCalendarsVisibility = async (
    calendarId,
    isVisible,
    settingId,
  ) => {
    const setting = { type: "isVisible", id: settingId, value: isVisible };

    setAvailableCalendars((prev) =>
      prev.map((el) =>
        el.id === calendarId
          ? { ...el, isVisible: { ...el.isVisible, value: isVisible } }
          : el,
      ),
    );

    await saveCalendarSetting(setting, calendarId, userId);
  };

  const handleCalendarsBackground = async (
    background,
    calendarId,
    settingId,
  ) => {
    const setting = { type: "background", id: settingId, value: background };

    setAvailableCalendars((prev) =>
      prev.map((el) =>
        el.id === calendarId
          ? { ...el, background: { ...el.background, value: background } }
          : el,
      ),
    );

    await saveCalendarSetting(setting, calendarId, userId);
  };

  const handleEventEdit = (ev) =>
    onEventEdit(ev).then(() =>
      getCalendarEvents(dataSource, context, component),
    );

  const handleEventMove = (ev) =>
    onEventMove(ev).then(() =>
      getCalendarEvents(dataSource, context, component),
    );

  return (
    <CalendarDragContextProvider>
      <Box
        ref={nodeRef}
        {...style}
        {...rootProps}
        {...restProps}
        position="relative"
        overflow="auto"
      >
        {isOpen && (
          <CalendarSettingsModal
            isOpen={isOpen}
            onClose={onClose}
            availableCalendars={availableCalendars}
            changeCalendarVisibility={handleCalendarsVisibility}
            changeCalendarBackground={handleCalendarsBackground}
          />
        )}
        {!designerMode && isContextMenuOpened && (
          <Box position="fixed" zIndex="155">
            <ContextMenu
              isOpen={isContextMenuOpened}
              onClose={closeContextMenu}
              actions={contextMenuActions}
              clickPosition={clickPosition}
            />
          </Box>
        )}

        <CalendarNavigation
          nextWeek={nextWeek}
          prevWeek={prevWeek}
          weekStart={weekStart}
          weekEnd={weekEnd}
        />

        <CalendarHeader weekDays={weekDays} onContextMenu={openContextMenu} />
        <CalendarBody
          weekDays={weekDays}
          events={data}
          availableCalendars={availableCalendars}
          onEventAdd={onEventAdd}
          onEventEdit={handleEventEdit}
          onEventMove={handleEventMove}
        />
      </Box>
    </CalendarDragContextProvider>
  );
};

Calendar.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  dataSource: PropTypes.any,
  onEventAdd: PropTypes.func,
  onEventEdit: PropTypes.func,
  onEventMove: PropTypes.func,
  nodeRef: PropTypes.any,
  style: PropTypes.object,
  rootProps: PropTypes.object,
  guid: PropTypes.any,
  nodeId: PropTypes.any,
  context: PropTypes.any,
  designerMode: PropTypes.any,
};

export default withBaseComponent(Calendar);
