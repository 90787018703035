import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button, HStack } from "@chakra-ui/react";

const GraphDesignerModal = ({
  isOpen,
  onClose,
  onEdit,
  propertiesComponent,
  modalProps,
  state,
}) => {
  const [editState, setEditState] = useState(state);

  const handleClose = () => {
    Promise.all([setEditState(null), onClose(false)]);
  };
  const handleSave = () => {
    Promise.all([setEditState(null), onClose(true), onEdit(editState)]);
  };

  return (
    <Modal
      isCentered
      className="disable-ppz-drag"
      {...modalProps}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent margin={0} width="90%" maxW="90%" height="90%" maxH="90%">
        <ModalHeader className="disable-ppz-drag">Properties</ModalHeader>
        <ModalCloseButton className="disable-ppz-drag" />
        <ModalBody className="disable-ppz-drag" minHeight={0}>
          <HStack minHeight={0} height="100%">
            {propertiesComponent(editState, setEditState)}
          </HStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSave} variant="ghost">
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

GraphDesignerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onEdit: PropTypes.func,
  modalProps: PropTypes.any,
  state: PropTypes.object,
  propertiesComponent: PropTypes.func,
};

export default GraphDesignerModal;
