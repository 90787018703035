import React from "react";
import { Box } from "@chakra-ui/react";
import { ContextMenu } from "@echo/ui";
import PropTypes from "prop-types";

export const ContextMenuWrapper = ({
  isOpen,
  onClose,
  clickPosition,
  actions,
  children,
}) => {
  return (
    <>
      {isOpen && actions && (
        <Box fontSize="14px">
          <ContextMenu
            isOpen={isOpen}
            onClose={onClose}
            clickPosition={clickPosition}
            actions={actions}
          />
        </Box>
      )}
      {children}
    </>
  );
};

ContextMenuWrapper.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  clickPosition: PropTypes.object,
  actions: PropTypes.array,
  children: PropTypes.node,
};
