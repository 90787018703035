import PropTypes from "prop-types";
import { Grid } from "@chakra-ui/react";
import { Tile } from "./components";
import "../../styles/index.css";
import { useCallback, useContext } from "react";
import EchoRouterContext from "../echo-router/echo-router-context";

const Dashboard = ({ padding = "8px", gap = "8px", tiles }) => {
  const { openPage } = useContext(EchoRouterContext);

  const handleOnClick = useCallback(
    (_, path, title) => {
      if (path) {
        openPage(path, null, title, true);
      }
    },
    [openPage],
  );

  return (
    <Grid
      justifyContent="center"
      alignContent="start"
      height="100%"
      gap={gap}
      padding={padding}
      templateColumns="repeat(auto-fit, min(calc(100px + 5vw),150px))"
      templateRows="repeat(auto-fit, min(calc(100px + 5vw),150px))"
      autoColumns
    >
      {tiles.map((tile, index) => (
        <Tile
          key={`dashboard-tile-${index}`}
          {...tile}
          onClick={handleOnClick}
        />
      ))}
    </Grid>
  );
};

Dashboard.propTypes = {
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      hoverColor: PropTypes.string,
      hoverBackgroundColor: PropTypes.string,
      rowSpan: PropTypes.number,
      colSpan: PropTypes.number,
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default Dashboard;
