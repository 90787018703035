import { showErrorToast } from "../../components/shared/echo-error-toast";

export const closePageBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve, reject) => {
        try {
          resolve(
            componentContext.routerContext.closePage(
              staticParams?.key,
              staticParams?.openedKey,
            ),
          );
        } catch (err) {
          showErrorToast(err);
          reject(err);
        }
      }),
  };
};
