import React, { useMemo } from "react";
import PropTypes from "prop-types";

const ExtensionComponent = ({ extensions, selectedExtensionName }) => {
  const SelectedExtensionComponent = useMemo(() => {
    const selectedExtension = extensions.find(
      (extension) => extension.name === selectedExtensionName,
    );

    return selectedExtension?.extensionComponent || null;
  }, [extensions, selectedExtensionName]);

  return (
    <div>{SelectedExtensionComponent && <SelectedExtensionComponent />}</div>
  );
};

ExtensionComponent.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  selectedExtensionName: PropTypes.string,
};

export default ExtensionComponent;
