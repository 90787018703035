import PropTypes from "prop-types";
import { withEchoComponentRenderer } from "../../../echo-component/function-wrapper/function-wrapper";
import EchoComponent from "./echo-component";
import ComponentShim from "../shim-components/component-shim/component-shim";
import { getCrudData } from "../../echo-components/utils/get-crud-data";
import { getEchoDefinedParent } from "../../echo-components/utils/definition-tree-utils/get-echo-defined-parent";
import { calculateProp } from "../../../echo-component/function-wrapper/calculate-prop";

const EchoDefinedComponent = ({
  styleOptions,
  componentProps,
  systemProps,
  context,
}) => {
  if (
    systemProps &&
    systemProps.component &&
    systemProps.component.childrenComponents &&
    systemProps.component.childrenComponents.length === 1
  ) {
    const { component } = systemProps;
    /* This child is the first component, that can be rendered.
     * !?echo-component is a logic wrapper for manipulate styles, props and functions.
     * It has only one child.
     * So do the logic, attach styles, logic, etc. and render the only child it has.
     */

    if (
      context?.component?.id &&
      component?.id &&
      context?.component?.id !== component?.id
    ) {
      const props = getEchoDefinedParent(
        context.component,
        component.id,
        component.guid,
        component.nodeId,
      )?.componentProps;
      let params = [];
      if (props) {
        const keys = Object.keys(props);
        params = keys
          .map((key) => {
            const prop = props[key];
            if (
              prop === null ||
              prop.type === "PROCESS" ||
              prop.type === "PROCESS_GROUP"
            ) {
              return null;
            }
            return {
              name: key,
              value: calculateProp(prop, context, props, component?.id) ?? null,
            };
          })
          .filter((param) => param !== null);
      }
      getCrudData({ context, component, params }, (result) => {
        // eslint-disable-next-line react/prop-types
        if (result && result[0] !== context.state[props.name]) {
          // eslint-disable-next-line react/prop-types
          context.functions.setStateProp(props.name, result[0]);
        }
      });
    }

    // TODO check system props overriding mechanism. In functionWrapper system props are overridden. Eg designerMode

    const child = component.childrenComponents[0];

    // TODO process mapping, process logic
    return withEchoComponentRenderer(
      <EchoComponent
        source={child.component.source}
        context={context}
        styleOptions={{
          ...child.styleOptions,
          ...styleOptions,
          ...systemProps.styleOptions,
        }}
        componentProps={{
          ...child.componentProps,
          ...componentProps,
          ...systemProps.styleOptions,
        }}
        systemProps={{
          id: systemProps.id,
          guid: systemProps.guid,
          nodeId: systemProps.nodeId,
          designerMode: false,
          state: systemProps.state,
          localContext: systemProps?.localContext,
          stateDispatch: systemProps.stateDispatch,
          context: systemProps.context,
          styleOptions: {
            ...child.styleOptions,
            ...systemProps.styleOptions,
          },
          childs: child.childrenComponents,
          component: child.component,
          path:
            component.type !== "Container"
              ? `${systemProps.path}${systemProps.path ? "." : ""}${
                  context?.component?.id !== component?.id &&
                  !systemProps.path &&
                  !componentProps?.useRootContext
                    ? componentProps.name || systemProps.id
                    : ""
                }`
              : systemProps.path,
        }}
      />,
    );
  }
  return <ComponentShim />;
};

EchoDefinedComponent.isWrapper = true;

EchoDefinedComponent.propTypes = {
  source: PropTypes.string.isRequired,
  componentProps: PropTypes.object,
  styleOptions: PropTypes.object,
  context: PropTypes.any,
  systemProps: PropTypes.shape({
    id: PropTypes.number.isRequired,
    guid: PropTypes.string.isRequired,
    nodeId: PropTypes.string.isRequired,
    childs: PropTypes.array,
    state: PropTypes.any,
    stateDispatch: PropTypes.func,
    styleOptions: PropTypes.object, // TODO is necessary ?
    componentProps: PropTypes.object, // TODO is necessary ?
    component: PropTypes.any,
    designerMode: PropTypes.bool,
    context: PropTypes.any,
    path: PropTypes.string.isRequired,
    localContext: PropTypes.any,
  }),
};

export default EchoDefinedComponent;
