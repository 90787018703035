import { useContext, useMemo, useState } from "react";
import { useSettings } from "./use-settings";
import { useData } from "./use-data";
import { useColumns } from "./use-columns";
import { showErrorToast } from "../../../../../echo-error-toast";
import UserContext from "../../../../../../../user-context";
import { mapAndSortColumns } from "../utils/map-and-sort-columns";
import { mergeColumns } from "../utils/merge-columns";
import { mapNumberColumnFormat } from "../utils/column-number-format-mapper";
import { useResolveProp } from "../../../../../../hooks/resolve-prop/use-resolve-prop";

export const useDatagrid = (props) => {
  const { userId, companyId } = useContext(UserContext);
  const {
    id,
    name,
    context,
    dataSource,
    onRowEdit,
    onRowAdd,
    disableHeader,
    relatedObjectId,
    disableSorting,
  } = props;

  const objectId = useResolveProp(relatedObjectId, true);

  const { resolvedColumns } = useColumns(props);

  const [addingNewRow, setAddingNewRow] = useState(false);

  const [settings, setSettings] = useSettings({
    id,
    dataSource,
    objectId,
    initialSettings: {
      disableHeader: !!disableHeader,
    },
    conditions: {
      disableSorting,
    },
  });

  const { state, onChange, refreshData, getData } = useData(
    context,
    dataSource,
    settings,
    name,
  );

  const settingsValue = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const { id, key, loading, ...restSettings } = settings;
    return restSettings;
  }, [settings]);

  const onFilterChange = (value) => onChange("filters", value);
  const onPageChange = (value) => onChange("page", value);

  const onSortChange = (sortArr) => {
    if (disableSorting) return;
    setSettings({ ...settingsValue, sorting: sortArr }, userId, companyId);
  };

  const onColumnChange = (columns, userId, roleId, type, companyId) =>
    setSettings({ ...settingsValue, columns }, userId, companyId, type);

  const onItemsPerPageChange = (itemsPerPage) =>
    setSettings({ ...settingsValue, itemsPerPage }, userId, companyId);

  const onHeaderVisibilityChange = (bool) =>
    setSettings({ ...settingsValue, disableHeader: bool }, userId, companyId);

  const onFiltersVisibilityChange = (bool) =>
    setSettings({ ...settingsValue, disableFilters: bool }, userId, companyId);

  const onNavPositionChange = (pos) =>
    setSettings({ ...settingsValue, navPosition: pos }, userId, companyId);

  const onHeaderWhiteSpaceChange = (value) =>
    setSettings(
      { ...settingsValue, headerWhiteSpace: value },
      userId,
      companyId,
    );

  const mergedColumns = useMemo(
    () =>
      mergeColumns(state.columns, settings.columns).map(mapNumberColumnFormat),
    [state.columns, settings.columns],
  );

  const mappedAndSortedColumns = useMemo(
    () => mapAndSortColumns(mergedColumns, resolvedColumns),
    [mergedColumns, resolvedColumns],
  );

  const addEmptyRow = () => setAddingNewRow((prev) => !prev);

  const handleRowAdd = async (row) => {
    if (!onRowAdd) {
      showErrorToast({
        reasonTitle: "Datagrid: onRowAdd",
        message: "Process onRowAdd is not defined.",
      });
      return;
    }

    try {
      await onRowAdd(row);
      setAddingNewRow(false);
      await refreshData();
    } catch (e) {
      showErrorToast(e);
    }
  };

  const handleRowEdit = async (row) => {
    if (!onRowEdit) {
      showErrorToast({
        reasonTitle: "Datagrid: onRowEdit",
        message: "Process onRowEdit is not defined.",
      });
      return;
    }

    try {
      await onRowEdit(row);
    } catch (e) {
      showErrorToast(e);
    } finally {
      await refreshData();
    }
  };

  const api = {
    onColumnChange,
    onPageChange,
    refreshData,
    getData,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onHeaderWhiteSpaceChange,
    onNavPositionChange,
    onRowEdit: handleRowEdit,
    onRowAdd: handleRowAdd,
    addEmptyRow,
    onSortChange: (val) => {
      onSortChange(val);
      onPageChange(0);
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      onPageChange(0);
    },
    onFilterChange: (val) => {
      onFilterChange(val);
      onPageChange(0);
    },
  };

  return {
    state: {
      ...state,
      data: state.data,
      columns: mappedAndSortedColumns,
      settings: {
        ...settings,
        columns: mergedColumns,
        itemsPerPage: { value: settings.itemsPerPage },
      },
    },
    api,
    options: {
      addingNewRow,
    },
  };
};
