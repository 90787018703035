import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { DatagridV2 } from "@echo/datagrid";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useDatagrid } from "./hooks/use-datagrid-v2";
import { useSelection } from "./hooks/use-selection";
import ColumnVisibilityManager from "../datagrid-component/header/column-visibility-manager/column-visibility-manager";
import { ColumnSettings } from "../datagrid-component/column-settings/column-settings";
import { getDatagridColumnsPropType } from "./column-prop-type";
import { useEffect } from "react";
import { getDatagridActionsPropType } from "./action-prop-type";
import { useActions } from "./hooks/use-actions";
import { ActionsList } from "./components/actions-list";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { useSummary } from "./hooks/use-summary";
import { useContextActions } from "./hooks/use-context-actions";
import { useTableActions } from "./hooks/use-table-actions";
import { getRelatedObjectPropType } from "./related-object-prop-type";

const DatagridV2Component = (props) => {
  const {
    rootProps,
    nodeRef,
    onRowClick,
    onRowDoubleClick,
    onSelectChange,
    selectionMode,
    disableNavOnLoading,
    actions: defaultActions,
    pageSizeSelectOptions,
    headerFontSize,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
    isCombo,
    filterColumnName,
    filterValue,
    isReadOnly,
  } = props;

  const isReadOnlyState = useResolveProp(isReadOnly, true);
  const { state, api, options } = useDatagrid(props);
  const { onColumnChange, onFilterChange, refreshData } = api;
  const { summaryValues, summaryOptions } = useSummary(state.summary);
  const { selectedItems, ...selectionFunctions } = useSelection(
    state.data,
    onSelectChange,
    selectionMode,
  );

  const {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    onOpen: onColumnsManagerOpen,
  } = useDisclosure();

  const {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    onOpen: onColumnsSettingsOpen,
  } = useDisclosure();

  const { contextActions, selectionContextActions } = useContextActions(
    { ...state, selectedItems },
    {
      ...api,
      ...selectionFunctions,
      onColumnsSettingsOpen,
      onColumnsManagerOpen,
    },
  );

  const tableActions = useTableActions({
    ...api,
    ...selectionFunctions,
    onRowClick,
    onRowDoubleClick,
  });

  const { actions } = useActions(
    props,
    { ...state, selectedItems },
    { ...api },
  );

  useEffect(() => {
    if (filterColumnName) {
      onFilterChange([
        {
          name: filterColumnName,
          values: [filterValue === "" ? null : filterValue],
          type: "contains",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterColumnName, filterValue]);

  const getActions = (props, actions, defaultActions) => {
    const { designerMode, enableAdding } = props;

    if (designerMode) {
      return enableAdding
        ? [{ text: "Add row" }, ...(defaultActions || [])]
        : defaultActions || [];
    }

    return actions || [];
  };

  return (
    <Box
      display="flex"
      ref={nodeRef}
      className={`$data-grid`}
      {...rootProps}
      height="100%"
      width="100%"
      overflowX="hidden"
      flexFlow="column"
      justifyContent="space-between"
      padding="5px"
      gap="10px"
    >
      <ActionsList
        actions={getActions(props, actions, defaultActions)}
        designerMode={props.designerMode}
      />
      <ColumnVisibilityManager
        isOpen={isColumnsManagerOpen}
        onClose={onColumnsManagerClose}
        columns={state.settings.columns}
        onChange={tableActions.onColumnVisibilityChange}
      />
      <ColumnSettings
        isOpen={isColumnsSettingsOpen}
        onClose={onColumnsSettingsClose}
        defaultColumns={state.settings.columns}
        settingsId={state.settings.id}
        settingsKey={state.settings.key}
        onChange={(val, userId, roleId, type, company) => {
          onColumnChange(val, userId, roleId, type, company);
          refreshData();
        }}
        sourceObject={state.source}
        isCombo={isCombo}
        actions={[
          {
            name: "Reset",
            buttonProps: { colorScheme: "red" },
            action: tableActions.resetAction,
          },
        ]}
        // eslint-disable-next-line react/prop-types
        parent={props?.component}
      />
      <DatagridV2
        data={state.data}
        columns={state.columns}
        actions={tableActions}
        page={state.page}
        filters={state.filters}
        sort={props.disableSorting ? [] : state.settings.sorting}
        itemsCount={state.itemsCount}
        loading={state.loading}
        selectionMode={isReadOnlyState ? "none" : props.selectionMode}
        enableAdding={options.addingNewRow}
        enableEdit={isReadOnlyState ? false : props.enableEdit}
        settings={state.settings}
        contextActions={contextActions}
        selectedItems={selectedItems}
        disableFilters={state.settings.disableFilters}
        disableHeader={state.settings.disableHeader}
        disablePagination={disableNavOnLoading && state.loading}
        pageSizeSelectOptions={pageSizeSelectOptions}
        headerFontSize={headerFontSize}
        rowFontSize={rowFontSize}
        cellWhiteSpace={cellWhiteSpace}
        cellLinesNumber={cellLinesNumber}
        navPosition={state.settings.navPosition}
        selectionContextActions={selectionContextActions}
        summaryOptions={summaryOptions}
        summaryValues={summaryValues}
      />
    </Box>
  );
};

DatagridV2Component.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.func,
  relatedObjectId: getRelatedObjectPropType(PropTypes.number),
  columns: getDatagridColumnsPropType(PropTypes.object),
  actions: getDatagridActionsPropType(PropTypes.object),
  context: PropTypes.any,
  rootProps: PropTypes.any,
  nodeRef: PropTypes.any,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowEdit: PropTypes.func,
  onSelectChange: PropTypes.func,
  disableHeader: PropTypes.bool,
  disableFilters: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disableNavOnLoading: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  enableAdding: PropTypes.bool,
  enableEdit: PropTypes.bool,
  isReadOnly: PropTypes.bool,

  columnKey: PropTypes.string,
  designerMode: PropTypes.bool,

  rowColor: PropTypes.func,
  pageSizeSelectOptions: PropTypes.array,

  headerFontSize: PropTypes.string,
  rowFontSize: PropTypes.string,
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,

  isCombo: PropTypes.bool,
  filterColumnName: PropTypes.string,
  filterValue: PropTypes.string,
};

export default withBaseComponent(DatagridV2Component);
