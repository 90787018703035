import { useContext } from "react";
import { DragContext } from "../context/drag-context";

export const useDragContext = () => {
  const context = useContext(DragContext);

  if (!context) throw new Error("Drag context is not initialized.");

  return context;
};
