import PropTypes from "prop-types";
import { Box, Button, Input, ListItem, Text } from "@chakra-ui/react";
import { RiCloseFill } from "react-icons/ri";
import PropertiesTable from "../../../properties-table/properties-table";

export const CustomListItem = (props) => {
  const { onChange, deleteItem, inputTypes, propertyTypes, ...rest } = props;
  const { name } = rest;

  return (
    <ListItem
      maxWidth="300px"
      flexShrink="0"
      flexGrow="0"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="10px">
          <Text>Name:</Text>
          <Input
            value={name}
            onChange={(e) => onChange({ ...rest, name: e.target.value })}
          />
        </Box>
        <Button padding="0" margin="0" onClick={deleteItem} variant="ghost">
          <RiCloseFill
            style={{
              padding: "0",
              margin: "0",
              fontSize: "24px",
            }}
          />
        </Button>
      </Box>
      <PropertiesTable
        options={propertyTypes}
        model={rest}
        onModelChange={onChange}
        customTypes={inputTypes}
      />
    </ListItem>
  );
};

CustomListItem.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  deleteItem: PropTypes.func,
  propertyTypes: PropTypes.array,
  inputTypes: PropTypes.array,
};
