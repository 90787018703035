import { getNumberFormatConfig } from "../../../../../../../app-config/find-config";

const checkColumnTypeNumber = (col) => {
  if (
    col?.type === "number" ||
    col?.type === "numeric" ||
    col?.editType === "number" ||
    col?.editType === "numeric"
  ) {
    return true;
  }

  return false;
};

export const mapNumberColumnFormat = (c) => {
  const defaultNumberFormat = getNumberFormatConfig();
  if (checkColumnTypeNumber(c)) {
    return { ...c, format: c.format || defaultNumberFormat };
  }

  return c;
};
