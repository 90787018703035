import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../user-context";
import {
  getCompaniesWithUserId,
  switchCompany,
} from "../../services/company-service";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Button, Select } from "@chakra-ui/react";
import { setToken } from "../../services/token-service";
import EchoRouterContext from "../echo-router/echo-router-context";

const ChangeCompanyModal = (props) => {
  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const { isOpen, onClose } = props;
  const { userId, companyId } = useContext(UserContext);
  const routerContext = useContext(EchoRouterContext);

  useEffect(() => {
    const sub = getCompaniesWithUserId(userId).then((data) => {
      setCompanies(() => data.filter((c) => c.id != companyId));
    });

    return () => sub;
  }, []);

  const handleSelectCompany = (e) => setSelectedCompany(e.target.value);

  const handleClick = useCallback(async () => {
    if (!isNaN(selectedCompany)) {
      routerContext.closeAllPages();
      const result = await switchCompany(Number(selectedCompany));
      setToken(result.token);
      window.location.reload();
    }
  }, [routerContext, selectedCompany]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Zmień spółkę</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            value={selectedCompany}
            onChange={handleSelectCompany}
            ml="5px"
            mt="4px"
            width="248px"
            placeholder="Select option"
          >
            {companies &&
              companies
                .sort((a, b) => a.id - b.id)
                .map((c) => (
                  <option key={`user-${c.id}`} value={c.id}>
                    {c.name}
                  </option>
                ))}
          </Select>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={!selectedCompany}
            colorScheme="blue"
            mr={3}
            onClick={handleClick}
          >
            Select
          </Button>
          <Button colorScheme="red" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ChangeCompanyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangeCompanyModal;
