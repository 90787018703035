import PropTypes from "prop-types";
import LabeledCheckboxFormControl from "../../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";
import { Box } from "@chakra-ui/react";
import { selectColumnWidth } from "../../utils/columns/column-utility";

const RowCheckbox = ({ selected, onChange, fontSize }) => {
  return (
    <Box
      as="td"
      fontSize={fontSize}
      display="table-cell"
      style={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        borderRight: "1px solid black",
        padding: "0 3px",
        width: selectColumnWidth,
        height: "100%",
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <LabeledCheckboxFormControl
        id="datagrid-row-checkbox"
        value={selected}
        onChange={(_, e) => {
          const options = { shifted: false, ctrl: false };
          if (e.ctrlKey) {
            options.ctrl = true;
          }
          if (e.shiftKey) {
            options.shifted = true;
          }
          onChange(!selected, options);
        }}
      />
    </Box>
  );
};

RowCheckbox.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RowCheckbox;
