import { executeProcess } from "./utils/execute-process";
import { executeProcessGroup } from "./utils/execute-process-group";
import { getCrudAction } from "./utils/get-crud-action";
import { getModelId } from "./utils/get-id";
import { getModel } from "./utils/get-model";
import { getModelChanges } from "./utils/get-model-changes";

const ACTION_TYPE_PROCESS = "PROCESS";
const ACTION_TYPE_BLOCK_GROUP = "BLOCK_GROUP";

export const crudActionBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams, params, systemParams) => {
      const model = getModel(staticParams, componentContext);
      const initialModel =
        componentContext.functions.getModel()?.initialFormSource;
      const changes = getModelChanges(initialModel, model);
      const id = getModelId(model);

      if (id && changes.length < 1) {
        return { ...staticParams, __model: model };
      }

      const action = getCrudAction(id, componentContext);

      if (!action) throw new Error("Crud action is not defined.");

      const unexpectedActionType =
        action.type !== ACTION_TYPE_PROCESS &&
        action.type !== ACTION_TYPE_BLOCK_GROUP;

      if (unexpectedActionType) throw new Error("Unexpected action type.");

      // eslint-disable-next-line no-useless-catch
      try {
        const args = [
          componentContext,
          staticParams,
          params,
          action,
          model,
          systemParams,
        ];

        if (action.type === ACTION_TYPE_BLOCK_GROUP) {
          const res = await executeProcessGroup(...args);
          return { ...staticParams, __model: res };
        }

        const res = await executeProcess(...args);
        return { ...staticParams, __model: res };
      } catch (e) {
        throw e;
      }
    },
  };
};
