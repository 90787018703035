import EchoComponent from "../../../echo-component";
import PropTypes from "prop-types";
import { getBelowContainerId } from "../../../../../echo-components/component-designer/utils/get-below-container-id";
import { getDesignerStyleOptions } from "../../../../../echo-components/component-designer/utils/get-designer-style-options";
import { parseDataValue } from "../../../../../echo-components/component-designer/utils/data-value-parser";
import { withEchoComponent } from "../../../../../../echo-component/function-wrapper/function-wrapper";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import DesignerChild from "./designer-child";

const handleOnDrag = (child, onAreaChange, e) => {
  const id = getBelowContainerId(e, child.id);
  onAreaChange(id);
};

const FlexDesignerComponent = ({
  id,
  path,
  childs,
  onComponentClick = shimFunctions.shimFunction1,
  designerMode = false,
  onMoveElement = shimFunctions.shimFunction1,

  activeAreaId = null,
  activePropertiesComponentId,
  depth = null,
  dragMode = false,
  onDropAreaChange = shimFunctions.shimFunction1,

  style = { width: "100px" },
  onClick = shimFunctions.shimFunction1,
  onMouseLeave = shimFunctions.shimFunction1,
  onMouseOver = shimFunctions.shimFunction1,
  nodeRef,
  onMouseDown = shimFunctions.shimFunction0,
  onMouseUp = shimFunctions.shimFunction0,
  onTouchEnd = shimFunctions.shimFunction0,
}) => {
  // TODO Refactor. Move all js and state logic to useFlexComponent hook
  const handleOnAreaClick = (event) => {
    event.preventDefault();
    event.stopPropagation(); // TODO not tested
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onClick(event);
    }
  };

  const handleAreaOnMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onMouseOver(event);
    }
  };

  const handleAreaOnMouseLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onMouseLeave(event);
    }
  };

  return (
    <div
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      ref={nodeRef}
      style={{
        display: "flex",
        height: "100%",
        boxSizing: "border-box",
        width: "100%",
        position: "relative",
        ...style,
      }}
      onMouseOver={handleAreaOnMouseOver}
      onMouseLeave={handleAreaOnMouseLeave}
      data-value={designerMode ? JSON.stringify({ depth, id }) : undefined}
      onClick={handleOnAreaClick}
    >
      {childs.map((child) => {
        return (
          <DesignerChild
            id={child.id}
            key={`child-${child.id}`}
            freeze={
              child.component.source !== "!?echo-defined" &&
              child.component.type !== "Container" &&
              child.component.type !== "Template"
            }
            onStop={(e, ui) => {
              if (Math.abs(ui.lastX) > 5 || Math.abs(ui.lastX) > 5) {
                Promise.all([onMoveElement(child)]);
              }
            }}
            onDrag={(e) => {
              handleOnDrag(child, onDropAreaChange, e);
            }}
          >
            {(childNodeRef) => {
              return withEchoComponent(
                <EchoComponent
                  source={child.component.source}
                  componentProps={child.componentProps}
                  childs={child.childrenComponents}
                  styleOptions={{
                    ...child.styleOptions,
                    ...getDesignerStyleOptions(
                      dragMode,
                      designerMode,
                      activePropertiesComponentId,
                      activeAreaId,
                      child.id,
                    ),
                  }}
                  systemProps={{
                    id: child.id,
                    guid: child.guid,
                    nodeId: child.nodeId,
                    depth: child.depth,
                    dragMode,
                    onMoveElement,
                    designerMode,
                    component: child.component,
                    styleOptions: {
                      ...child.styleOptions,
                      ...getDesignerStyleOptions(
                        dragMode,
                        designerMode,
                        activePropertiesComponentId,
                        activeAreaId,
                        child.id,
                      ),
                    },
                    childs: child.childrenComponents,
                    onClick: (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onComponentClick(child.id);
                    },
                    onComponentClick,
                    activeAreaId,
                    onDropAreaChange,
                    activePropertiesComponentId,
                    onMouseLeave: () => onDropAreaChange(id),
                    nodeRef: childNodeRef,
                    path,
                  }}
                />,
                "designer",
              );
            }}
          </DesignerChild>
        );
      })}
    </div>
  );
};

FlexDesignerComponent.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  nodeId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  childs: PropTypes.array.isRequired,
  onComponentClick: PropTypes.funcOf(PropTypes.number),
  designerMode: PropTypes.bool, // local mode
  onMoveElement: PropTypes.funcOf(PropTypes.object),

  activeAreaId: PropTypes.number,
  activePropertiesComponentId: PropTypes.number,
  depth: PropTypes.number,
  dragMode: PropTypes.bool,
  onDropAreaChange: PropTypes.funcOf(PropTypes.number),

  nodeRef: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.funcOf(PropTypes.object),
  onMouseOver: PropTypes.funcOf(PropTypes.object),
  onMouseLeave: PropTypes.funcOf(PropTypes.object),
  onMouseDown: PropTypes.funcOf(PropTypes.any),
  onMouseUp: PropTypes.funcOf(PropTypes.any),
  onTouchEnd: PropTypes.funcOf(PropTypes.any),
};

export default FlexDesignerComponent;
