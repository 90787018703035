import { formatIntegers } from "./format-integers";
import { getValueWithFraction } from "./fraction";
import { localeDecimalSeparator } from "./locale-decimal-separator";
import { splitFormat } from "./split-format";
import { splitValue } from "./split-value";
import { isDigit } from "./validation";

const getDefaultValue = (separator, fraction) => {
  let result = "0";

  if (separator) result += localeDecimalSeparator;
  if (fraction > 0) {
    for (let i = 0; i < fraction; i++) {
      result += "0";
    }
  }

  return result;
};

const getNumericValue = (value, groupSeparator, decimalSeparator) => {
  let result = value;
  let separatorFound = false;

  result = result
    .split("")
    .reverse()
    .map((c) => {
      if (!isDigit(c) && !separatorFound && decimalSeparator) {
        separatorFound = true;
        return ".";
      } else if (
        groupSeparator &&
        !isDigit(c) &&
        (separatorFound || !decimalSeparator)
      ) {
        return "";
      }

      return c;
    })
    .reverse()
    .join("");

  // if (!groupSeparator || (groupSeparator && groupSeparator !== ","))
  //   result = result.replace(",", ".");

  return parseFloat(result);
};

export const formatValue = (value, format) => {
  try {
    const { intFormat, groupSeparator, decimalSeparator, decimalPlaces } =
      splitFormat(format);

    if (!value) return getDefaultValue(decimalSeparator, decimalPlaces);

    const stringValue = value.toString();
    const isNegative = stringValue.startsWith("-");

    const numericValue = getNumericValue(
      isNegative ? stringValue.replace("-", "") : stringValue,
      groupSeparator,
      decimalSeparator,
    );

    const roundedValue = numericValue.toString().replace(".", decimalSeparator);

    const valueWithFraction = getValueWithFraction(
      roundedValue,
      decimalSeparator,
      decimalPlaces,
    );

    const { integers, fraction } = splitValue(
      valueWithFraction,
      decimalSeparator,
    );

    const formattedInts = formatIntegers(integers, intFormat, groupSeparator);

    const result =
      (isNegative ? "-" : "") +
      (formattedInts ? formattedInts : "") +
      (decimalSeparator ? localeDecimalSeparator : "") +
      (fraction ? fraction : "");

    return result;
  } catch {
    return value;
  }
};

export const getBaseValue = (value, format) => {
  const { groupSeparator, decimalSeparator } = splitFormat(format);

  return value
    .toString()
    .replaceAll(groupSeparator, "")
    .replace(decimalSeparator, ".")
    .replace(localeDecimalSeparator, ".");
};
