import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { ItemsList } from "./items-list";
import { actionPropertyTypes } from "../utils/property-types";
import { inputTypes } from "../utils/input-types";
import { toast } from "react-toastify";

export const DatagridActionsEditor = (props) => {
  const { propValue, designerPortalRef, onChange } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [actions, setActions] = useState(propValue);

  const addAction = () => setActions((prev) => [...prev, {}]);

  const updateAction = (action, idx) => {
    setActions((prev) => prev.map((el, id) => (id === idx ? action : el)));
  };

  const deleteAction = (action, idx) => {
    setActions((prev) => prev.filter((el, id) => id !== idx));
  };
  const restoreActions = () => {
    setActions(propValue);
  };

  const handleCancel = () => {
    restoreActions();
    onClose();
  };

  const saveActions = () => {
    onChange(actions);
    onClose();
    toast.success("Saved successfully.");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button onClick={onOpen} color="none" variant="ghost" size="md">
        {actions && actions.length.toString()}...
      </Button>
      <Modal
        portalProps={{ containerRef: designerPortalRef }}
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Datagrid actions</ModalHeader>
          <ModalBody>
            <ItemsList
              items={actions}
              onItemChange={updateAction}
              deleteItem={deleteAction}
              propertyTypes={actionPropertyTypes}
              inputTypes={inputTypes}
            />
            <Button onClick={addAction} variant="ghost">
              Add new
            </Button>
          </ModalBody>
          <ModalFooter gap="20px">
            <Button onClick={handleCancel} variant="ghost">
              Cancel
            </Button>
            <Button onClick={saveActions} colorScheme="blue">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DatagridActionsEditor.propTypes = {
  onChange: PropTypes.func,
  propName: PropTypes.string,
  propType: PropTypes.string,
  propValue: PropTypes.string,
  designerPortalRef: PropTypes.any,
};
