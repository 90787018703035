// @flow
import React from "react";
import type { Column } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { EditCell } from "./edit-cell";
import { DisplayCell } from "./display-cell";
import { Box } from "@chakra-ui/react";
import { SummaryCell } from "./summary-cell";
import { Cell } from "./cell";

type FooterCellProps = {
  column: Column,
  value: string | Date | number,
  row: any,
  onClick: () => void,
};

export const FooterCell = (props: FooterCellProps) => {
  const { enableAdding, summaryOptions } = useDatagridContext();
  const { column } = props;
  const { columns: summaryColumns } = summaryOptions;

  return summaryColumns &&
    summaryColumns.length > 0 &&
    summaryColumns.includes(column.name) ? (
    <SummaryCell column={column} width={column.width} />
  ) : (
    <Cell column={column} width={column.width} />
  );

  // if column.type is number then show summing cell
  // else show empty cell

  // return enableAdding ? (
  //   <EditCell column={column} onChange={onChange} value={value} row={row} />
  // ) : (
  //   <DisplayCell
  //     column={column}
  //     value={value}
  //     onClick={onClick}
  //     style={style}
  //     row={row}
  //   />
  // );
};
