// @flow

import React, { useEffect, useState } from "react";
import { DatePicker } from "../datepicker/datepicker";
import { FilterInputProps } from "./filter-input";
import { FilterOptionType } from "./filter-options";
import { Box } from "@chakra-ui/react";

type DateFilterInputProps = {
  type: FilterOptionType,
  onChange: (value) => void,
  values: string[],
  format: string,
};

export const DateFilterInput = (props: DateFilterInputProps) => {
  const { type, values, format, onChange } = props;

  return type === "between" || type === "excludeRange" ? (
    <Box display="flex" alignItems="center" gap="5px">
      <DatePicker
        value={values[0] || ""}
        onChange={(val) => onChange(val, 0)}
        dateFormat={format || "dd-MM-yyyy"}
        size="xs"
      />
      <DatePicker
        value={values[1] || ""}
        onChange={(val) => onChange(val, 1)}
        dateFormat={format || "dd-MM-yyyy"}
        size="xs"
      />
    </Box>
  ) : (
    <DatePicker
      value={values[0] || ""}
      onChange={onChange}
      dateFormat={format || "dd-MM-yyyy"}
      size="xs"
    />
  );
};
