import { useCallback, useContext, useRef } from "react";
import UserHeaderComponent from "./user-header-component";
import { LockIcon } from "@chakra-ui/icons";
import "../../styles/main-component/header-menu.css";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";
import UserContext from "../../user-context";
import { useHistory } from "../echo-router/hooks";
import { useRefDimensions } from "@echo/ui";
import { useCompanies } from "../hooks/companies/use-companies";
import CompanyHeaderComponent from "./company-header-component";
import EchoRouterContext from "../echo-router/echo-router-context";
import "../../styles/cursor/cursor.css";
import { clearLocalStorageWithException } from "../../utils/local-storage/local-storage";
import { localStorageKeys } from "../../utils/local-storage/local-storage-keys";

const AppHeader = () => {
  const {
    signOut,
    userName,
    companyId,
    isSuperuser,
    isSourceContextSuperuser,
  } = useContext(UserContext);
  const history = useHistory();
  const { goToStart } = useContext(EchoRouterContext);
  const contentRef = useRef();
  const [companies] = useCompanies();

  const handleLogoClick = useCallback(() => {
    goToStart();
  }, [goToStart]);

  const [contentDimensions] = useRefDimensions(contentRef, {
    reactOnResize: true,
  });

  // eslint-disable-next-line eqeqeq
  const company = companies.find((c) => c.id == companyId);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <Box
      ref={contentRef}
      display="flex"
      flexDirection="row"
      boxSizing="border-box"
      height="48px"
      backgroundColor="#E8190C"
      alignItems="center"
      justifyContent="space-between"
      padding="0px 8px"
    >
      <img
        style={{ height: "40px", cursor: "pointer" }}
        height="40px"
        src={
          contentDimensions?.width > 768
            ? "https://www.caldo-izolacja.pl/www/images/caldo-ogolne-biale.png"
            : "https://www.caldo-izolacja.pl/www/images/caldo-ogolne-biale_mobile.png"
        }
        alt="Caldo"
        onClick={handleLogoClick}
      />
      <Box
        display="flex"
        flexDirection="row"
        boxSizing="border-box"
        alignItems="center"
        justifyContent="flex-end"
      >
        <HStack spacing="32px">
          {contentDimensions?.width > 768 && (
            <CompanyHeaderComponent company={company} />
          )}
          <UserHeaderComponent
            userName={userName}
            isSuperuser={isSuperuser || isSourceContextSuperuser}
          />
          <IconButton variant="outline" onClick={onOpen}>
            <LockIcon color="white" />
          </IconButton>
        </HStack>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader></AlertDialogHeader>
            <AlertDialogBody
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="1.5rem"
            >
              Czy na pewno chcesz się wylogować?
            </AlertDialogBody>
            <AlertDialogFooter
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button ref={cancelRef} onClick={onClose} size="md">
                Anuluj
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  const exceptions = [
                    localStorageKeys.GOOGLE_AUTH_INFO,
                    localStorageKeys.GOOGLE_AUTH_STATUS,
                  ];
                  clearLocalStorageWithException(exceptions);
                  history.push("/");
                  signOut();
                }}
                size="md"
                ml={3}
              >
                Wyloguj
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

AppHeader.propTypes = {};

export default AppHeader;
