import { DatePicker } from "@echo/ui";
import React from "react";

export const InputDateEditCell = (props) => {
  const { value, onChange, autoFocus, dateFormat } = props;
  return (
    <DatePicker
      date={value}
      onChange={onChange}
      autoFocus={autoFocus}
      format={dateFormat || "dd-MM-yyyy"}
    />
  );
};
