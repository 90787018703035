import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { useState } from "react";
import PropTypes from "prop-types";
import { Link, Text } from "@chakra-ui/react";

export const MarkerWithInfoWindow = ({ children, marker, defaultIsOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [markerRef, markerObj] = useAdvancedMarkerRef();

  const { position, icon, name } = marker;

  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <AdvancedMarker
        title="title"
        ref={markerRef}
        onClick={handleClick}
        position={position}
      >
        {icon && <Pin glyph={new URL(String(icon))} glyphColor="#fff" />}
      </AdvancedMarker>

      {isOpen && (
        <InfoWindow
          disableAutoPan
          onClose={handleClose}
          anchor={markerObj}
          headerContent={
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {name}
            </Text>
          }
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {children}
          <Link
            href={`https://www.google.com/maps/?q=${position.lat()},${position.lng()}`}
            target="_blank"
          >
            Pokaż w Google Maps
          </Link>
        </InfoWindow>
      )}
    </>
  );
};

MarkerWithInfoWindow.propTypes = {
  children: PropTypes.object,
  marker: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.string,
    address: PropTypes.string,
  }),
  defaultIsOpen: PropTypes.bool,
};
